import { lgKeyCodes, samsungKeyCodes } from '@/core/keys/keyCodes'

const ChannelUpLGKey = 'PageUp'
const ChannelUpTizenKey = 'XF86RaiseChannel'
const ChannelUpVIDAAKey = 'VK_CHANNEL_UP'
const LocalChannelUpKey = '+'

export const isChannelUp = (e?: KeyboardEvent) => {
  if (!e) return false
  if (!e.key) return false

  return (
    e.key === ChannelUpLGKey ||
    e.key === ChannelUpTizenKey ||
    e.key === LocalChannelUpKey ||
    e.key === ChannelUpVIDAAKey ||
    e.keyCode === lgKeyCodes.BUTTON_CHANNEL_NEXT ||
    e.keyCode === samsungKeyCodes.BUTTON_CHANNEL_NEXT
  )
}
