import TizenService from './tizen.service'
import WebOsService from './webOS.service'
import { NetworkInfo, Platform, ProductInfo, PlatformInfo } from '@/models/platform.model'

export default class PlatformService {
  static get isTizen() {
    return Object.prototype.hasOwnProperty.call(window, 'tizen')
  }

  static get isWebOs() {
    return !PlatformService.isTizen && Object.prototype.hasOwnProperty.call(window, 'webOS')
  }

  static get isVidaa() {
    return (
      !PlatformService.isWebOs &&
      !PlatformService.isTizen &&
      Object.prototype.hasOwnProperty.call(window, 'vidaatv')
    )
  }

  static get isLocal() {
    return !this.isTizen && !this.isWebOs
  }

  static get platform() {
    if (PlatformService.isTizen) return Platform.TIZEN
    if (PlatformService.isWebOs) return Platform.WEB_OS
    if (PlatformService.isVidaa) return Platform.VIDAA
    if (PlatformService.isLocal && window.location.hostname === 'localhost') return Platform.LOCAL

    return null
  }

  static volumeUp() {
    if (PlatformService.isTizen) TizenService.volumeUp()
    else if (PlatformService.isWebOs) WebOsService.volumeUp()
  }

  static volumeDown() {
    if (PlatformService.isTizen) TizenService.volumeDown()
    else if (PlatformService.isWebOs) WebOsService.volumeDown()
  }

  static exit() {
    if (PlatformService.isTizen) TizenService.exit()
    else if (PlatformService.isWebOs) WebOsService.exit()
  }

  static disconnect() {
    if (PlatformService.isTizen) TizenService.disconnect()
  }

  static setup() {
    if (PlatformService.isTizen) TizenService.setup(/*options.tizen*/)
    else if (PlatformService.isWebOs) WebOsService.setup()
  }

  static async fetchProductInfo() {
    let info = {} as ProductInfo
    if (PlatformService.isTizen) {
      info = await TizenService.fetchProductInfo()
    } else if (PlatformService.isWebOs) {
      info = await WebOsService.fetchProductInfo()
    }

    return info
  }

  static async fetchNetworkInfo() {
    let info = {} as NetworkInfo
    if (PlatformService.isTizen) {
      info = await TizenService.fetchNetworkInfo()
    } else if (PlatformService.isWebOs) {
      info = await WebOsService.fetchNetworkInfo()
    }

    return info
  }

  static async fetchTizenInfo(): Promise<Partial<PlatformInfo>> {
    return TizenService.fetchInfo()
  }

  static async fetchWebOsInfo(): Promise<Partial<PlatformInfo>> {
    return WebOsService.fetchInfo()
  }

  static async fetchInfo(): Promise<Partial<PlatformInfo>> {
    let info = {} as Partial<PlatformInfo>
    if (PlatformService.isTizen) {
      info = await PlatformService.fetchTizenInfo()
    } else if (PlatformService.isWebOs) {
      info = await PlatformService.fetchWebOsInfo()
    }

    return info
  }
}
