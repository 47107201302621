import { createApi } from '@reduxjs/toolkit/query/react'
import { createEntityAdapter, EntityState } from '@reduxjs/toolkit'

import { CHANNELS_API_URL } from '@/core/config'
import { apiBaseQuery } from '@/core/api/api.query'

import { ChannelModel } from '@/models/channel.model'

enum FavoritesApiTag {
  ALL_FAVORITES_CHANNELS = 'ALL_FAVORITES_CHANNELS',
}

export const favoritesChannelsAdapter = createEntityAdapter<ChannelModel>({
  selectId: (model) => model.id,
})

export const favoritesChannelsSelectors = favoritesChannelsAdapter.getSelectors()

export const favoritesChannelsApi = createApi({
  reducerPath: 'favoritesChannelsApi',
  baseQuery: apiBaseQuery({ baseUrl: `${CHANNELS_API_URL}/clients/me/favorites/` }),
  tagTypes: [FavoritesApiTag.ALL_FAVORITES_CHANNELS],
  endpoints: (builder) => ({
    // CHANNELS FAVORITES
    getAllMeFavoritesChannels: builder.query<EntityState<ChannelModel>, void>({
      query: () => {
        return {
          url: 'channels',
          method: 'GET',
          params: {
            relations: 'logo',
          },
        }
      },
      transformResponse: (response: ChannelModel[]) => {
        return favoritesChannelsAdapter.addMany(
          favoritesChannelsAdapter.getInitialState(),
          response,
        )
      },
      serializeQueryArgs: ({ endpointName }) => {
        return endpointName
      },
      providesTags: [FavoritesApiTag.ALL_FAVORITES_CHANNELS],
    }),

    deleteAllChannelsFromFavorites: builder.mutation<ChannelModel[], void>({
      query: () => {
        return {
          url: 'channels',
          method: 'DELETE',
        }
      },
      invalidatesTags: [FavoritesApiTag.ALL_FAVORITES_CHANNELS],
    }),

    getFavoritesChannelByChannelId: builder.query<'', { id: string }>({
      query: ({ id }) => {
        return {
          url: `channels/${id}`,
          method: 'GET',
        }
      },
    }),

    addChannelToFavorites: builder.mutation<ChannelModel, { id: string }>({
      query: ({ id }) => {
        return {
          url: `channels/${id}`,
          method: 'POST',
        }
      },
      invalidatesTags: [FavoritesApiTag.ALL_FAVORITES_CHANNELS],
    }),

    removeChannelFromFavorites: builder.mutation<ChannelModel, { id: string }>({
      query: ({ id }) => {
        return {
          url: `channels/${id}`,
          method: 'DELETE',
        }
      },
      invalidatesTags: [FavoritesApiTag.ALL_FAVORITES_CHANNELS],
    }),
  }),
})

export const {
  useGetAllMeFavoritesChannelsQuery,
  useAddChannelToFavoritesMutation,
  useRemoveChannelFromFavoritesMutation,
} = favoritesChannelsApi
