import React, { FC, memo, useCallback, useMemo, useState } from 'react'
import classNames from 'classnames'
import styles from './styles.module.scss'

import ChosenContentDescription from '@/modules/content/components/ChosenContentDescription'
import ImageLoader from '@/components/Image'
import {
  getPromoteChannelResourcePosterUrl,
  getPromoteContentResourcePosterUrl,
  PosterExtension,
  PosterOrientation,
} from '@/modules/content/content.helpers'
import SkeletonView from '@/components/SkeletonView'
import {
  isPromoteChannelResource,
  isPromoteContentResource,
  PromotesResourceModel,
} from '@/models/promotesResource.model'
import ShortProgramListWithDescription from '@/modules/tv/components/ShortProgramListWithDescription'

import { getChannelLogoUrl } from '@/modules/tv/tv.helpers'

import { ChannelModel } from '@/models/channel.model'
import { ProgramBaseModel } from '@/models/program.model'

interface PromoCardInnerProps<T> {
  card?: T
}

const PromoCardInner = <T extends PromotesResourceModel>({ card }: PromoCardInnerProps<T>) => {
  const [livePoster, setLivePoster] = useState<string>()

  const url = useMemo(() => {
    if (card === undefined) return

    if (isPromoteChannelResource(card)) {
      return getPromoteChannelResourcePosterUrl({
        promote: card,
        extension: PosterExtension.WebP,
        orientation: PosterOrientation.VERTICAL,
        query: {
          w: 750,
          q: 70,
        },
      })
    }

    if (isPromoteContentResource(card))
      return getPromoteContentResourcePosterUrl({
        promote: card,
        extension: PosterExtension.WebP,
        query: {
          w: 750,
          q: 70,
        },
      })
  }, [card])

  const getLive = useCallback((live?: ProgramBaseModel) => {
    if (!live || !live.partnerPosterSource) {
      setLivePoster(undefined)
      return
    }

    setLivePoster(live.partnerPosterSource)
  }, [])

  return (
    <div
      className={classNames(styles.PromoCardInner, {
        [styles.Short]: card && isPromoteChannelResource(card),
      })}
    >
      {!card && <SkeletonView />}
      {card && isPromoteContentResource(card) && (
        <ChosenContentDescription
          card={card.resource}
          className={styles.DescriptionContainer}
          descriptionClassName={styles.Description}
        />
      )}

      {card && isPromoteChannelResource(card) && (
        <ChannelDescription card={card.resource} getLive={getLive} />
      )}

      <div
        className={classNames(styles.ImageBackground, {
          [styles.ImageBackground__Channel]: card && isPromoteChannelResource(card),
        })}
      >
        <ImageLoader src={livePoster || url} alt={''} fallbackSrc={url} />
      </div>
    </div>
  )
}

interface Props {
  card: ChannelModel
  getLive?: (live?: ProgramBaseModel) => void
}

const ChannelDescription: FC<Props> = ({ card, getLive }) => {
  return (
    <div
      className={classNames(styles.ChannelDescription, styles.DescriptionContainer, {
        [styles.DescriptionContainer__Channel]: true,
      })}
    >
      <div className={styles.LogoAndTitle}>
        {getChannelLogoUrl(card) && (
          <div className={styles.ChannelLogo}>
            <ImageLoader src={getChannelLogoUrl(card)} alt={'logo_url'} />
          </div>
        )}
        <h1 className={classNames(styles.Title)}>{card.title}</h1>
      </div>
      <div className={styles.ProgramContainer}>
        <ShortProgramListWithDescription channel={card} getLive={getLive} />
      </div>
    </div>
  )
}

export default memo(PromoCardInner)
