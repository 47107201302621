import React, { useCallback, useEffect, useState } from 'react'

import styles from './styles.module.scss'

import Loader from '@/components/Loader'
import EmptyFavorites from '@/modules/favorites/components/EmptyFavorites'
import ChannelsSlider from '@/modules/tv/components/ChannelsSlider'

import { useNavigate } from 'react-router-dom'

import { useAppDispatch, useAppSelector } from '@/core/store'

import {
  favoritesChannelsAdapter,
  favoritesChannelsSelectors,
  useGetAllMeFavoritesChannelsQuery,
  useRemoveChannelFromFavoritesMutation,
} from '@/modules/favorites/favorites.channels.api'
import { setChosenCategory, setChosenChannel } from '@/modules/tv/tv.actions'

import { ChannelModel } from '@/models/channel.model'
import { ContentPaths, Paths } from '@/core/router/router.paths'
import Checkbox from '@/components/Checkbox'
import { feedsApi } from '@/modules/feeds/feeds.api'
import { CategoryModel } from '@/models/category.model'

const FavoritesChannelsListOrEmpty = () => {
  const navigate = useNavigate()

  const { 0: isEditMode, 1: setEditMode } = useState(false)

  const dispatch = useAppDispatch()

  const profile = useAppSelector((state) => state.profile.data.profile)

  const {
    data: channels,
    isLoading,
    isFetching,
  } = useGetAllMeFavoritesChannelsQuery(undefined, {
    skip: !profile,
    selectFromResult: ({ data, ...rest }) => ({
      data: favoritesChannelsSelectors.selectAll(
        data ?? favoritesChannelsAdapter.getInitialState(),
      ),
      ...rest,
    }),
  })

  const [removeChannelFromFavorites, { isLoading: deleting, originalArgs }] =
    useRemoveChannelFromFavoritesMutation()

  const resetEditMode = useCallback(() => {
    setEditMode(false)
  }, [])

  useEffect(() => {
    if (!channels) return
    if (!isEditMode) return

    if (channels.length === 0) {
      resetEditMode()
    }
  }, [channels])

  const handleChannelPress = useCallback(
    (card: ChannelModel) => {
      if (!card) return

      if (isEditMode) {
        removeChannelFromFavorites({ id: card.id })
        dispatch(feedsApi.util.invalidateTags(['FAVORITE-CHANNEL-RESOURCES']))
        return
      }

      dispatch(setChosenChannel(card))
      dispatch(setChosenCategory({ id: 'favorites', pluralTitle: 'Избранное' } as CategoryModel))
      navigate(`${Paths.CONTENT}/${ContentPaths.TV}`)
    },
    [isEditMode],
  )
  return (
    <div className={styles.FavoritesChannels}>
      {isLoading && (
        <Loader>
          <Loader.Spinner />
        </Loader>
      )}
      <h2 className={styles.Title}>ТВ каналы</h2>
      <div className={styles.EditMode}>
        <Checkbox
          checked={isEditMode}
          onChange={setEditMode}
          name={`${!isEditMode ? 'Включить' : 'Выключить'} режим удаления`}
          className={styles.Checkbox}
          disabled={
            !channels || isLoading || isFetching || deleting || (channels && channels.length === 0)
          }
        />
      </div>
      {((!channels && !isLoading && !isFetching) || !channels.length) && (
        <div className={styles.EmptyFavoritesContainer}>
          <EmptyFavorites
            text={'Здесь будут отображаться Ваши избранные каналы'}
            className={styles.Empty}
          />
        </div>
      )}
      {channels && !!channels.length && (
        <ChannelsSlider
          data={channels}
          onCardPress={handleChannelPress}
          className={styles.Wrapper}
          rowProps={{
            title: '',
            contentWrapperClassName: styles.ContentWrapper,
          }}
          deletable={isEditMode}
          deleting={deleting}
          deletingId={originalArgs && originalArgs.id}
        />
      )}
    </div>
  )
}

export default FavoritesChannelsListOrEmpty
