import { BasePosterModel } from '@/models/poster.model'
import { ContentModel } from '@/models/content.model'
import { ChannelModel } from '@/models/channel.model'

import { isChannel, isContent } from '@/modules/feeds/components/ResourcesList/helpers'

export interface ResourcePromotePoster extends Omit<BasePosterModel, 'type' | 'contentId'> {
  promoteResourceId: string
  horizontalImageId: string | null
  verticalImageId: string | null
}

export type Resource = ContentModel | ChannelModel | null

export interface PromotesResourceModel<T extends Resource = Resource> {
  id: string
  createdAt: string
  updatedAt: string
  resourceId: string
  promoteId: string
  resourceType: 'CHANNEL' | 'CONTENT' | 'BLANK'
  poster?: ResourcePromotePoster | null
  resource: T
}

export const isPromoteContentResource = (
  promoteResource: PromotesResourceModel,
): promoteResource is PromotesResourceModel<ContentModel> => {
  const type = promoteResource.resourceType
  if (type !== 'CONTENT') return false
  const resource = promoteResource.resource
  return isContent(resource as ContentModel)
}

export const isPromoteMovieResource = (
  promoteResource: PromotesResourceModel<ContentModel>,
): promoteResource is PromotesResourceModel<ContentModel> => {
  return promoteResource.resource.type === 'MOVIE'
}

export const isPromoteSerialResource = (
  promoteResource: PromotesResourceModel<ContentModel>,
): promoteResource is PromotesResourceModel<ContentModel> => {
  return promoteResource.resource.type === 'SERIAL'
}

export const isPromoteChannelResource = (
  promoteResource: PromotesResourceModel,
): promoteResource is PromotesResourceModel<ChannelModel> => {
  const type = promoteResource.resourceType
  if (type !== 'CHANNEL') return false
  const resource = promoteResource.resource
  return isChannel(resource as ChannelModel)
}
