import React from 'react'
import { ContentModel } from '@/models/content.model'
import PartnerLogo from '@/modules/content/components/PartnerLogo'

import premier from '@/assets/images/partners/premier.yellow.svg'
import start from '@/assets/images/partners/start.red.svg'
import amediateka from '@/assets/images/partners/amediateka.white.svg'
// import rutube from '@/assets/images/partners/rutube.white.color.svg'
import rutube from '@/assets/images/partners/rutube.svg'
import seasonvar from '@/assets/images/partners/seasonvar.white.cat.svg'

import { PartnerValue } from '@/models/partner.model'
import classNames from 'classnames'

interface ResolveAndRenderPartnerProps {
  card?: ContentModel
  className?: string
}

const getPartnerLogo = (partner?: PartnerValue | null): string | undefined => {
  switch (partner) {
    case 'PREMIER':
      return premier
    case 'START':
      return start
    case 'AMEDIATEKA':
      return amediateka
    case 'RUTUBE':
      return rutube
    case 'SEASONVAR':
      return seasonvar
    default:
      return
  }
}

const ResolveAndRenderPartner = ({ className, card }: ResolveAndRenderPartnerProps) => {
  if (!card) return null
  const partner = card.partner
  if (!partner) return null

  const type = partner.type
  if (!type) return null

  const logo = getPartnerLogo(type)
  if (!logo) return null
  return <PartnerLogo logo={logo} className={classNames(className)} />
}

export default ResolveAndRenderPartner
