import React, { FC, useCallback, useEffect, useState } from 'react'

import classNames from 'classnames'
import styles from './styles.module.scss'

import { useNavigate } from 'react-router-dom'

import Loader from '@/components/Loader'
import List, { ListProps } from '@/components/List'
import EmptyFavorites from '@/modules/favorites/components/EmptyFavorites'
import Checkbox from '@/components/Checkbox'
import DeletableFavoritesCard from '@/modules/favorites/components/DeletableFavoritesCard'

import {
  favoritesContentAdapter,
  favoritesContentSelectors,
  useGetAllFavoritesContentQuery,
  useRemoveContentFromFavoritesMutation,
} from '@/modules/favorites/favorites.media.api'
import { feedsApi } from '@/modules/feeds/feeds.api'

import { useAppDispatch, useAppSelector } from '@/core/store'

import { setChosenCard } from '@/modules/content/content.actions'

import { ContentModel, ContentType } from '@/models/content.model'
import { MovieModel } from '@/models/movie.model'
import { ContentPaths, Paths } from '@/core/router/router.paths'

interface FavoritesContentsListOrEmptyProps extends Omit<ListProps, 'children'> {
  type?: ContentType
  containerClassName?: string
}

const FavoritesContentsListOrEmpty: FC<FavoritesContentsListOrEmptyProps> = ({
  type,
  containerClassName,
  ...rest
}) => {
  const navigate = useNavigate()
  const dispatch = useAppDispatch()

  const { 0: isEditMode, 1: setEditMode } = useState(false)

  const profile = useAppSelector((state) => state.profile.data.profile)

  const {
    data: favorites,
    isLoading: favoritesLoading,
    isFetching: favoritesFetching,
  } = useGetAllFavoritesContentQuery(
    { type },
    {
      skip: !profile,
      selectFromResult: ({ data, ...rest }) => ({
        data: favoritesContentSelectors.selectAll(
          data ?? favoritesContentAdapter.getInitialState(),
        ),
        ...rest,
      }),
    },
  )

  const resetEditMode = useCallback(() => {
    setEditMode(false)
  }, [])

  useEffect(() => {
    if (!favorites) return
    if (!isEditMode) return

    if (favorites.length === 0) {
      resetEditMode()
    }
  }, [favorites])

  const [removeContentFromFavorites, { isLoading: deleting, originalArgs }] =
    useRemoveContentFromFavoritesMutation()

  const isMovie = useCallback((card: ContentModel): card is MovieModel => {
    return card.type === ContentType.MOVIE
  }, [])

  const handleOnPress = useCallback(
    (card: ContentModel) => {
      if (!card) return

      if (isEditMode) {
        removeContentFromFavorites({ id: card.id })
        dispatch(feedsApi.util.invalidateTags(['FAVORITE_CONTENT_RESOURCES']))
        return
      }

      dispatch(setChosenCard(card))
      navigate(
        `${Paths.CONTENT}/${isMovie(card) ? ContentPaths.MOVIES : ContentPaths.SERIALS}/${card.id}`,
      )
    },
    [isEditMode],
  )

  return (
    <>
      {type && (
        <div className={styles.EditMode}>
          <Checkbox
            checked={isEditMode}
            onChange={setEditMode}
            name={`${!isEditMode ? 'Включить' : 'Выключить'} режим удаления`}
            className={styles.Checkbox}
            disabled={
              !favorites ||
              (favorites && favoritesLoading) ||
              favoritesFetching ||
              deleting ||
              (favorites && favorites.length === 0)
            }
          />
        </div>
      )}
      <div className={classNames(containerClassName, styles.Contents)}>
        {favoritesLoading && (
          <Loader>
            <Loader.Spinner />
          </Loader>
        )}
        {!type && <h2 className={styles.Title}>Фильмы и сериалы</h2>}
        {!type && (
          <div className={styles.EditMode}>
            <Checkbox
              checked={isEditMode}
              onChange={setEditMode}
              name={`${!isEditMode ? 'Включить' : 'Выключить'} режим удаления`}
              className={styles.Checkbox}
              disabled={
                !favorites ||
                (favorites && favoritesLoading) ||
                favoritesFetching ||
                deleting ||
                (favorites && favorites.length === 0)
              }
            />
          </div>
        )}
        {(!favorites || !favorites.length) && !favoritesLoading && !favoritesFetching && (
          <EmptyFavorites
            className={styles.Empty}
            text={'Здесь будут отображаться Ваши избранные фильмы и сериалы.'}
          />
        )}

        {favorites && favorites.length > 0 && (
          <List {...rest}>
            {favorites.map((item, index) => {
              return (
                <DeletableFavoritesCard
                  key={item.id}
                  autoFocus={index === 0}
                  card={item}
                  wrapperClassName={styles.Card}
                  onCardPress={handleOnPress}
                  deletable={isEditMode}
                  deleting={deleting && originalArgs && originalArgs.id === item.id}
                />
              )
            })}
          </List>
        )}
      </div>
    </>
  )
}

export default FavoritesContentsListOrEmpty
