import React, { FC, useCallback, useEffect, useMemo } from 'react'

import styles from './styles.module.scss'

import Button from '@/components/Button'

import { useAppDispatch, useAppSelector } from '@/core/store'

import { useLazyGetProgramsByChannelIdAndDateQuery } from '@/modules/tv/tv.api'

import { setCurrentProgram } from '@/modules/tv/tv.actions'
import { ArchiveStatuses } from '@/models/channel.model'

interface PrevProgramAction {
  className?: string
  disabled?: boolean
}

const PrevProgramAction: FC<PrevProgramAction> = ({ className, disabled }) => {
  const dispatch = useAppDispatch()

  const channel = useAppSelector((state) => state.tv.chosenChannel)
  const currentProgram = useAppSelector((state) => state.tv.currentProgram)
  // const liveProgram = useAppSelector((state) => state.tv.liveProgram)

  const [getPrograms, { data, isLoading }] = useLazyGetProgramsByChannelIdAndDateQuery()

  useEffect(() => {
    if (!currentProgram) return
    const currentDate = currentProgram?.startTime.split('T')[0]
    getPrograms({ date: currentDate, channelId: currentProgram.channelId }, true)
  }, [currentProgram])

  const prevProgram = useMemo(() => {
    if (!currentProgram || !data) return

    const currentProgramIndex = data.findIndex((el) => {
      return el.id === currentProgram.id
    })

    if (currentProgramIndex === -1 || currentProgramIndex === 0) return

    return data[currentProgramIndex - 1]
  }, [currentProgram, data])

  const handlePrevProgramAction = useCallback(() => {
    if (!prevProgram) return
    dispatch(setCurrentProgram(prevProgram))
  }, [prevProgram])

  if (!prevProgram || !channel || channel.archiveStatus !== ArchiveStatuses.AVAILABLE) return null

  return (
    <Button
      buttonClassName={className}
      disabled={isLoading || disabled}
      onButtonClick={handlePrevProgramAction}
      onEnterPress={handlePrevProgramAction}
    >
      <>
        <i className={'icon-fill-next'} style={{ transform: 'rotate(180deg)' }} />
        {/*<span>Предыдущая программа</span>*/}
        <span data-title={'title'} className={styles.Title}>
          {prevProgram.title}
        </span>
      </>
    </Button>
  )
}

export default PrevProgramAction
