import { MutableRefObject, ReactElement, useCallback, useEffect, useMemo, useRef } from 'react'

import { LOCAL_WATCH_EVENT_HB_TIME } from '@/core/config'
import { useSyncOneMediaView } from '@/modules/syncMediaView/hooks/useSyncOneMediaView'

import type { ContentModel } from '@/models/content.model'
import { ContentType } from '@/models/content.model'
import type { EpisodeBaseModel } from '@/models/serial.model'
import type { MovieBaseModel } from '@/models/movie.model'
import type { SyncMediaViewModelWithResource } from '@/models/sync.media.view.model'
import type { CommonPlayerEventsCallback } from '@/modules/player/Player/PlayerVideoManager'
import type { EventsRenderParameters } from '@/modules/player/Player/EventsRenderParams.type'

export interface PlayerEventManagerProps extends Partial<EventsRenderParameters> {
  content: ContentModel
  movie?: MovieBaseModel
  episode?: EpisodeBaseModel
  url: string
  minutesForWatchEvent?: number
  preferredLanguage?: string
  viewInfo?: SyncMediaViewModelWithResource | null
  children: (params: Partial<EventsRenderParameters>) => ReactElement
}

export const UNAVAILABLE_VIDEO_URL = 'prof.mp4'

export const clearTimeOutIfItDefined = (
  ref: MutableRefObject<ReturnType<typeof setTimeout> | null>,
) => {
  if (!ref.current) return

  clearTimeout(ref.current)
  ref.current = null
}

const PlayerSyncViewManager = ({
  children,
  disabled: extDisabled,
  url,
  content,
  movie,
  episode,
  preferredLanguage,
  viewInfo,
  ...props
}: PlayerEventManagerProps) => {
  const { syncWithVideoEl } = useSyncOneMediaView({
    viewInfo,
    preferredLanguage,
    movie,
    episode,
    content,
  })

  const videoElRef = useRef<HTMLVideoElement | null>(null)
  const onTimeUpdateTimeRef = useRef<ReturnType<typeof setTimeout> | null>(null)
  const previousContentIdRef = useRef<string | undefined>(content.id)
  const contentIdRef = useRef<string | undefined>(content.id)
  const episodeIdRef = useRef<string | undefined>(episode?.id)
  const previousEpisodeIdRef = useRef<string | undefined>(episode?.id)
  const previousCurrentTime = useRef(0)
  const isMovieRef = useRef(content.type === ContentType.MOVIE)

  useEffect(() => {
    if (!episode) return
    episodeIdRef.current = episode.id
  }, [episode])

  useEffect(() => {
    if (!content) return
    contentIdRef.current = content.id
  }, [content])

  const disabled = useMemo(
    () => extDisabled || url.includes(UNAVAILABLE_VIDEO_URL),
    [url, extDisabled],
  )

  const onChangeUrl: CommonPlayerEventsCallback = useCallback(
    (videoEl) => {
      videoElRef.current = videoEl
      clearTimeOutIfItDefined(onTimeUpdateTimeRef)
      //   save currentTime after resolution or lang change
      if (content.type === 'MOVIE' && contentIdRef.current === previousContentIdRef.current) {
        videoEl.currentTime = previousCurrentTime.current || 0
        clearTimeOutIfItDefined(onTimeUpdateTimeRef)
        return
      }

      if (
        content.type === 'SERIAL' &&
        content.id === previousContentIdRef.current &&
        episodeIdRef.current &&
        episodeIdRef.current === previousEpisodeIdRef.current
      ) {
        clearTimeOutIfItDefined(onTimeUpdateTimeRef)
        videoEl.currentTime = previousCurrentTime.current || 0
        return
      }

      videoEl.currentTime = 0
      previousEpisodeIdRef.current = episodeIdRef.current
    },
    [props.onChangeUrl],
  )

  const onPause: CommonPlayerEventsCallback = useCallback(
    (videoEl) => {
      videoElRef.current = videoEl
      props.onPause?.(videoEl)

      clearTimeOutIfItDefined(onTimeUpdateTimeRef)
      syncWithVideoEl(videoEl)
    },
    [props.onPause, episode],
  )

  const onStop: CommonPlayerEventsCallback = useCallback(
    (videoEl) => {
      videoElRef.current = videoEl
      props.onStop?.(videoEl)

      clearTimeOutIfItDefined(onTimeUpdateTimeRef)
      syncWithVideoEl(videoEl)
    },
    [props.onStop, episode],
  )

  const onEndRewind: CommonPlayerEventsCallback = useCallback(
    (videoEl) => {
      videoElRef.current = videoEl
      props.onEndRewind?.(videoEl)

      clearTimeOutIfItDefined(onTimeUpdateTimeRef)
      syncWithVideoEl(videoEl)
    },
    [props.onEndRewind, episode],
  )

  const onTimeUpdate: CommonPlayerEventsCallback = useCallback(
    (videoEl) => {
      videoElRef.current = videoEl
      previousCurrentTime.current = videoEl.currentTime
      props.onTimeUpdate?.(videoEl)

      if (onTimeUpdateTimeRef.current) return

      onTimeUpdateTimeRef.current = setTimeout(() => {
        syncWithVideoEl(videoEl)
        clearTimeOutIfItDefined(onTimeUpdateTimeRef)
      }, LOCAL_WATCH_EVENT_HB_TIME * 1000)
    },
    [props.onTimeUpdate],
  )

  useEffect(() => {
    return () => {
      clearTimeOutIfItDefined(onTimeUpdateTimeRef)
      const videoEl = videoElRef.current

      if (!videoEl) return

      if (videoEl.currentTime === 0 && isMovieRef.current) {
        return
      }

      syncWithVideoEl(videoEl)
    }
  }, [])

  const renderParams: Partial<EventsRenderParameters> = useMemo(() => {
    return {
      ...props,
      disabled,
      onTimeUpdate,
      onPause,
      onEndRewind,
      onStop,
      onChangeUrl,
    }
  }, [disabled, onTimeUpdate, onPause, onEndRewind, onStop, onChangeUrl])
  return children(renderParams)
}

export default PlayerSyncViewManager
