import { v4 } from 'uuid'
import { Platform, PlatformInfo } from '@/models/platform.model'
import PlatformService from '@/core/services/platform.service'
import { LoginDTO } from '@/modules/auth/auth.service'

import packageJson from '~/package.json'
import { getFromLocalStorage, saveToLocalStorage } from '@/core/utils'

const COUNTRY_CODE_ERROR = 'Телефон должен начинаться с кода страны 375'
const OPERATOR_CODE_ERROR =
  'Телефонный код оператора связи должен быть либо 25, либо 29, либо 33, либо 44'

export const checkValueAsPhone = (value: string, key: string): string | undefined => {
  if (value.length === 0) {
    if (key !== '3') {
      return COUNTRY_CODE_ERROR
    }
    return
  }

  if (value.length === 1) {
    if (key !== '7') {
      return COUNTRY_CODE_ERROR
    }
    return
  }

  if (value.length === 2) {
    if (key !== '5') {
      return COUNTRY_CODE_ERROR
    }
    return
  }

  if (value.length === 3) {
    if (!(key === '2' || key === '3' || key === '4')) {
      return OPERATOR_CODE_ERROR
    }
    return
  }

  if (value.length === 4) {
    if (value.endsWith('2') && !(key === '5' || key === '9')) {
      return OPERATOR_CODE_ERROR
    }

    if (value.endsWith('3') && key !== '3') {
      return OPERATOR_CODE_ERROR
    }

    if (value.endsWith('4') && key !== '4') {
      return OPERATOR_CODE_ERROR
    }
    return
  }

  return
}

const getCachedProductUid = async (productUid?: string): Promise<string> => {
  const savedPUid = getFromLocalStorage<string>('pUid')

  if (savedPUid) return savedPUid

  const savedFPUid = getFromLocalStorage<string>('fpUid')

  if (!productUid) {
    if (savedFPUid) return savedFPUid

    const result = v4()

    saveToLocalStorage('fpUid', result)

    return result
  }

  saveToLocalStorage<string>('pUid', productUid)

  return productUid
}

const getCachedModel = (productModel?: string) => {
  const model = getFromLocalStorage<string>('pModel')

  if (model) {
    if (productModel && productModel !== model) {
      saveToLocalStorage('pModel', productModel)
      return productModel
    }

    return model
  }

  if (productModel) {
    saveToLocalStorage('pModel', productModel)
    return productModel
  }

  if (PlatformService.platform === Platform.LOCAL) {
    return 'SANDBOX'
  }

  return 'UNKNOWN'
}

const getCachedPlatform = (): 'TIZEN' | 'WEB_OS' | 'VIDAA' | 'UNKNOWN' | 'SANDBOX' => {
  const platform = getFromLocalStorage<'TIZEN' | 'WEB_OS' | 'VIDAA' | 'UNKNOWN' | 'SANDBOX'>('plat')

  if (platform) return platform

  const pl =
    PlatformService.platform === 'WEB_OS'
      ? ('WEB_OS' as const)
      : PlatformService.platform === 'TIZEN'
      ? ('TIZEN' as const)
      : PlatformService.platform === 'VIDAA'
      ? ('VIDAA' as const)
      : undefined

  if (pl) {
    saveToLocalStorage('plat', pl)

    return pl
  }

  if (PlatformService.platform === 'LOCAL') {
    return 'SANDBOX' as const
  }

  return 'UNKNOWN' as const
}

export const preparePlatformInfo = async (info: Partial<PlatformInfo>) => {
  const productUid = await getCachedProductUid(info.product?.uid)
  const productModel = getCachedModel(info.product?.model)

  const device = {
    // uid: PlatformService.platform === Platform.LOCAL ? 'SANDBOX' : productUid || 'UNKNOWN',
    uid: productUid,
    model: productModel,
  }

  const app = {
    type: 'ITV_3',
  } as const
  const version = info.app?.version

  const application = { ...app, version: packageJson.version || version || '0.0.1' }

  const systemVersion = info.system?.version
  const platform = {
    type: getCachedPlatform(),
    version: PlatformService.platform === Platform.LOCAL ? 'SANDBOX' : systemVersion || 'UNKNOWN',
  }

  const deviceApplicationInfo: Omit<LoginDTO, 'credentials'> = {
    device,
    application,
    platform,
  }

  return deviceApplicationInfo
}
