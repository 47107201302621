export const {
  NODE_ENV,
  REACT_APP_SERVER_URL,
  REACT_APP_API_VERSION,
  REACT_APP_MEDIA_API_URL,
  REACT_APP_TV_API_URL,
  REACT_APP_AUTH_API_URL,
  REACT_APP_NOTIFICATION_API_URL,
  REACT_APP_ACCESS_TOKEN_KEY,
  REACT_APP_REFRESH_TOKEN_KEY,
  REACT_APP_CDN_API_BASE_URL,
  REACT_APP_SESSION_ID,
  REACT_APP_TRAILER_SKIP_TIME,
  REACT_APP_TRAILER_DELAY_TIME,
  REACT_APP_GET_LIVE_POLLING_INTERVAL,
  REACT_APP_MAX_NUMBER_OF_PROFILES,
  REACT_APP_MAX_LENGTH_OF_PROFILE_NAME,
  REACT_APP_PREFERRED_LANGUAGE,
  REACT_APP_LOCAL_WATCH_EVENT_HB_TIME,
  REACT_APP_SKIP_TIMELINE_TIME,
  REACT_APP_CHUNK_SIZE,
  REACT_APP_SOCKET_BASE_URL,
  REACT_APP_SOCKET_PORT,
  REACT_APP_SOCKET_NOTIFICATIONS_NAMESPACE,
  REACT_APP_AUTH_SESSION_NAMESPACE,
  REACT_APP_SEASONVAR_URL,
  REACT_APP_SHOULD_GET_SEASONVAR_STREAMS_FROM_FRONT,

  REACT_APP_EVENT_SOCKET_URL,
  REACT_APP_TV_EVENT_NAMESPACE,
  REACT_APP_MEDIA_EVENT_NAMESPACE,
  REACT_APP_NOTIFICATION_SOCKETS_ENABLED,
  REACT_APP_SESSION_SOCKETS_ENABLED,

  REACT_APP_TV_EVENT_OPTIONS_LOCAL_STORAGE_KEY,
  REACT_APP_MEDIA_EVENT_OPTIONS_LOCAL_STORAGE_KEY,

  REACT_APP_PREMIER_STATISTICS_ENABLED,
  REACT_APP_PREMIER_STATISTICS_API_URL,
  REACT_APP_PREMIER_STATISTICS_VIEW_LG_PATH,
  REACT_APP_PREMIER_STATISTICS_VIEW_SAMSUNG_PATH,
  REACT_APP_PREMIER_STATISTICS_HB_LG_PATH,
  REACT_APP_PREMIER_STATISTICS_HB_SAMSUNG_PATH,
} = process.env

export const IS_DEVELOPMENT = NODE_ENV === 'development'
export const IS_PRODUCTION = !IS_DEVELOPMENT
export const IS_NOTIFICATION_SOCKETS_ENABLED = REACT_APP_NOTIFICATION_SOCKETS_ENABLED === 'true'
export const IS_SESSION_SOCKETS_ENABLED = REACT_APP_SESSION_SOCKETS_ENABLED === 'true'

export const SERVER_URL = REACT_APP_SERVER_URL || 'http://localhost:3000'
export const MEDIA_API_SERVER_URL = REACT_APP_MEDIA_API_URL || 'http://localhost:3000'
export const TV_API_SERVER_URL = REACT_APP_TV_API_URL || 'http://localhost:3000'
export const AUTH_API_SERVER_URL = REACT_APP_AUTH_API_URL || 'http://localhost:3000'
export const NOTIFICATION_SERVER_URL = REACT_APP_NOTIFICATION_API_URL || 'http://localhost:3000'

export const API_VERSION = REACT_APP_API_VERSION || 'v1'

export const generateApiUrl = (url: string = SERVER_URL, version: string = API_VERSION) => {
  return `${url}/api/${version}`
}

export const API_URL = generateApiUrl()
export const MEDIA_API_URL = generateApiUrl(MEDIA_API_SERVER_URL)
export const CHANNELS_API_URL = generateApiUrl(TV_API_SERVER_URL)
export const AUTH_API_URL = generateApiUrl(AUTH_API_SERVER_URL)
export const NOTIFICATION_API_URL = generateApiUrl(NOTIFICATION_SERVER_URL)

export const SOCKET_NOTIFICATIONS_URL = `${NOTIFICATION_SERVER_URL}/${REACT_APP_SOCKET_NOTIFICATIONS_NAMESPACE}`
export const SOCKET_AUTH_SESSION_URL = `${AUTH_API_SERVER_URL}/${REACT_APP_AUTH_SESSION_NAMESPACE}`
export const SOCKET_TV_EVENT_URL = `${REACT_APP_EVENT_SOCKET_URL}/${REACT_APP_TV_EVENT_NAMESPACE}`
export const SOCKET_MEDIA_EVENT_URL = `${REACT_APP_EVENT_SOCKET_URL}/${REACT_APP_MEDIA_EVENT_NAMESPACE}`
export const TV_EVENT_OPTIONS_LOCAL_STORAGE_KEY = `${REACT_APP_TV_EVENT_OPTIONS_LOCAL_STORAGE_KEY}`
export const MEDIA_EVENT_OPTIONS_LOCAL_STORAGE_KEY = `${REACT_APP_MEDIA_EVENT_OPTIONS_LOCAL_STORAGE_KEY}`

export const CDN_API_BASE_URL = REACT_APP_CDN_API_BASE_URL || 'https://cdn.itv.by'
export const CDN_IMAGES_API_BASE_URL = `${CDN_API_BASE_URL}/images` || 'https://cdn.itv.by/images'

export const ACCESS_TOKEN_KEY = REACT_APP_ACCESS_TOKEN_KEY || 'ITV_3_ACCESS_TOKEN'
export const REFRESH_TOKEN_KEY = REACT_APP_REFRESH_TOKEN_KEY || 'ITV_3_REFRESH_TOKEN'
export const SESSION_ID = REACT_APP_SESSION_ID || 'ITV_3_SESSION_ID'
export const PREFERRED_LANGUAGE = REACT_APP_PREFERRED_LANGUAGE || 'ITV_3_PREFERRED_LANGUAGE'

export const TRAILER_SKIP_TIME = REACT_APP_TRAILER_SKIP_TIME || '5'
export const TRAILER_DELAY_TIME = REACT_APP_TRAILER_DELAY_TIME || '2'

export const GET_LIVE_POLLING_INTERVAL = REACT_APP_GET_LIVE_POLLING_INTERVAL || 60000

export const MAX_NUMBER_OF_PROFILES = REACT_APP_MAX_NUMBER_OF_PROFILES
  ? +REACT_APP_MAX_NUMBER_OF_PROFILES
  : 8
export const MAX_LENGTH_OF_PROFILE_NAME = REACT_APP_MAX_LENGTH_OF_PROFILE_NAME
  ? +REACT_APP_MAX_LENGTH_OF_PROFILE_NAME
  : 20

export const LOCAL_WATCH_EVENT_HB_TIME = REACT_APP_LOCAL_WATCH_EVENT_HB_TIME
  ? +REACT_APP_LOCAL_WATCH_EVENT_HB_TIME
  : 30

export const SKIP_TIMELINE_TIME = REACT_APP_SKIP_TIMELINE_TIME ? +REACT_APP_SKIP_TIMELINE_TIME : 30

export const RESOURCES_CHUNK_SIZE = REACT_APP_CHUNK_SIZE ? +REACT_APP_CHUNK_SIZE : 3

export const SEASONVAR_URL = REACT_APP_SEASONVAR_URL || 'http://seasonvar.ru'
export const SHOULD_GET_SEASONVAR_STREAMS_FROM_FRONT =
  REACT_APP_SHOULD_GET_SEASONVAR_STREAMS_FROM_FRONT === 'true'

// todo: add ability for disable premier stat
export const PREMIER_STATISTICS_ENABLED = REACT_APP_PREMIER_STATISTICS_ENABLED === 'true' || false
export const PREMIER_STATISTICS_API_URL = REACT_APP_PREMIER_STATISTICS_API_URL
export const PREMIER_STATISTICS_VIEW_LG_PATH = REACT_APP_PREMIER_STATISTICS_VIEW_LG_PATH
export const PREMIER_STATISTICS_VIEW_SAMSUNG_PATH = REACT_APP_PREMIER_STATISTICS_VIEW_SAMSUNG_PATH
export const PREMIER_STATISTICS_HB_LG_PATH = REACT_APP_PREMIER_STATISTICS_HB_LG_PATH
export const PREMIER_STATISTICS_HB_SAMSUNG_PATH = REACT_APP_PREMIER_STATISTICS_HB_SAMSUNG_PATH
