import { createApi } from '@reduxjs/toolkit/query/react'

import { MEDIA_API_URL } from '@/core/config'
import { apiBaseQuery } from '@/core/api/api.query'
import { preparePagination, prepareContentRelations } from '@/core/helpers'

import { ContentModel } from '@/models/content.model'
import { ContentArgs } from '@/modules/content/content.types'
import { ApiFetchAllWithPaginationPayload } from '@/core/api/api'
import { SerialModel } from '@/models/serial.model'

export const contentApi = createApi({
  reducerPath: 'contentApi',
  baseQuery: apiBaseQuery({ baseUrl: `${MEDIA_API_URL}/` }),
  tagTypes: [],
  endpoints: (builder) => ({
    getAllContent: builder.query<ContentModel[], Omit<ContentArgs, 'contentId' | 'paginate'>>({
      query: ({ relations: rel }) => {
        const relations = prepareContentRelations(rel || {})
        const params = { relations }
        return { url: 'contents', method: 'GET', params }
      },
    }),
    getContentByPaginate: builder.query<
      ApiFetchAllWithPaginationPayload<ContentModel>,
      Omit<ContentArgs, 'contentId'>
    >({
      query: ({ relations: rel, paginate: pag }) => {
        const relations = prepareContentRelations(rel || {})
        const paginate = preparePagination(pag)
        const params = { relations, paginate }
        return {
          url: 'contents',
          method: 'GET',
          params,
        }
      },
    }),
    getContentById: builder.query<ContentModel, ContentArgs>({
      query: ({ contentId, relations: rel }) => {
        const relations = prepareContentRelations(rel || {})
        const params = { relations }
        return { url: `contents/${contentId}`, method: 'GET', params }
      },
      transformResponse: (response: ContentModel) => {
        const typedResponse = response as SerialModel
        const serial = typedResponse.serial
        if (!serial) return response

        const seasons = typedResponse.serial?.seasons
        const sortedSeasons =
          seasons && seasons.length > 1
            ? [...seasons].sort(({ number: prev }, { number: next }) => prev - next)
            : seasons

        const sortedSeasonsWithSortedEpisodes = sortedSeasons?.map((el) => {
          const episodes = el.episodes ? [...el.episodes] : []
          const sortedEpisodes = episodes.sort(({ number: prev }, { number: next }) => prev - next)

          return { ...el, episodes: sortedEpisodes }
        })

        const preparedResponse: SerialModel = {
          ...typedResponse,
          serial: { ...serial, seasons: sortedSeasonsWithSortedEpisodes },
        }
        return preparedResponse
      },
    }),
    contentRightsCheck: builder.query<
      { streamAccessToken: string },
      Omit<ContentArgs, 'relations' | 'paginate'>
    >({
      query: ({ contentId }) => {
        return { url: `clients/me/contents/${contentId}/access`, method: 'GET' }
      },
      keepUnusedDataFor: 1,
    }),

    getAvailableYears: builder.query<string[], void>({
      query: () => ({
        url: 'clients/contents/years',
        method: 'GET',
      }),
    }),
  }),
})

export const {
  useGetAllContentQuery,
  useContentRightsCheckQuery,
  useLazyContentRightsCheckQuery,
  useGetContentByPaginateQuery,
  useGetContentByIdQuery,
  useGetAvailableYearsQuery,
} = contentApi
