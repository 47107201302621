import React, { FC } from 'react'

import PromotesResourceSlider from '@/modules/promotes/components/PromotesResourceSlider'

import {
  UnionContentType,
  useGetResourcesFromPromotesByResourceTypeQuery,
} from '@/modules/promotes/promotes.api'

interface Props {
  contentType?: UnionContentType | 'resources'
  shouldAutoFocusPromoCard?: boolean
  onAutoFocus?: () => void
}

const FetchAndRenderPromotesContent: FC<Props> = ({
  contentType = 'contents',
  shouldAutoFocusPromoCard,
  onAutoFocus,
}) => {
  const { data, isLoading } = useGetResourcesFromPromotesByResourceTypeQuery({ type: contentType })
  return (
    <PromotesResourceSlider
      promos={data}
      loading={isLoading}
      shouldAutoFocusPromoCard={shouldAutoFocusPromoCard}
      onAutoFocus={onAutoFocus}
    />
  )
}

export default FetchAndRenderPromotesContent
