import { genreApi } from '@/modules/genre/genre.api'
import { contentApi } from '@/modules/content/content.api'
import { categoryApi } from '@/modules/category/category.api'
import { tvApi } from '@/modules/tv/tv.api'
import { countriesApi } from '@/modules/countries/countries.api'
import { partnerApi } from '@/modules/partner/partner.api'
import { profileApi } from '@/modules/profile/profile.api'
import { subscriptionApi } from '@/modules/subscriptions/subscriptions.api'
import { paymentsApi } from '@/modules/payments/paymentsApi'
import { feedsApi } from '@/modules/feeds/feeds.api'
import { tagsApi } from '@/modules/tags/tags.api'
import { promotesApi } from '@/modules/promotes/promotes.api'
import { syncMediaViewApi } from '@/modules/syncMediaView/syncMediaView.api'
import { notificationsApi } from '@/modules/notifications/notifications.api'
import { devicesApi } from '@/modules/devices/devices.api'
import { streamsMediaApi } from '@/modules/streams/streams.media.api'
import { streamsChannelsApi } from '@/modules/streams/streams.channels.api'
import { favoritesMediaApi } from '@/modules/favorites/favorites.media.api'
import { favoritesChannelsApi } from '@/modules/favorites/favorites.channels.api'

export const middleware = [
  genreApi.middleware,
  notificationsApi.middleware,
  contentApi.middleware,
  categoryApi.middleware,
  tvApi.middleware,
  streamsMediaApi.middleware,
  streamsChannelsApi.middleware,
  favoritesMediaApi.middleware,
  favoritesChannelsApi.middleware,
  countriesApi.middleware,
  partnerApi.middleware,
  profileApi.middleware,
  subscriptionApi.middleware,
  paymentsApi.middleware,
  feedsApi.middleware,
  tagsApi.middleware,
  promotesApi.middleware,
  syncMediaViewApi.middleware,
  devicesApi.middleware,
]
