import React, { FC, JSX, useCallback, useEffect, useMemo, useRef } from 'react'
import {
  isPromoteChannelResource,
  isPromoteContentResource,
  PromotesResourceModel,
} from '@/models/promotesResource.model'
import { useNavigate } from 'react-router-dom'
import { useAppDispatch } from '@/core/store'
import { ContentType } from '@/models/content.model'
import { setChosenCard } from '@/modules/content/content.actions'
import { ContentPaths, Paths } from '@/core/router/router.paths'
import PromoCard from '@/modules/promotes/components/PromoCard'
import Loader from '@/components/Loader'
import Spinner from '@/components/Loaders/Spinner'
import PromoSlider from '@/modules/promotes/components/PromoSlider'
import { setChosenChannel, setFocusedChannel } from '@/modules/tv/tv.actions'

interface Props {
  promos?: PromotesResourceModel[]
  loading?: boolean
  shouldAutoFocusPromoCard?: boolean
  onAutoFocus?: () => void
}

const PromotesResourceSlider: FC<Props> = ({
  promos,
  loading,
  shouldAutoFocusPromoCard = false,
  onAutoFocus,
}) => {
  const navigate = useNavigate()

  const dispatch = useAppDispatch()

  const shouldAutoFocusPromoCardRef = useRef(shouldAutoFocusPromoCard)

  useEffect(() => {
    shouldAutoFocusPromoCardRef.current = shouldAutoFocusPromoCard
  }, [shouldAutoFocusPromoCard])

  const handleCardPress = useCallback((item: PromotesResourceModel) => {
    if (!item) return

    if (isPromoteChannelResource(item)) {
      dispatch(setChosenChannel(item.resource))
      dispatch(setFocusedChannel(item.resource))
      return
    }

    if (!isPromoteContentResource(item)) return

    if (item.resource.type === ContentType.MOVIE) {
      dispatch(setChosenCard(item.resource))
      navigate(`${Paths.CONTENT}/${ContentPaths.MOVIES}/${item.resourceId}`)
      return
    }

    if (item.resource.type === ContentType.SERIAL) {
      dispatch(setChosenCard(item.resource))
      navigate(`${Paths.CONTENT}/${ContentPaths.SERIALS}/${item.resourceId}`)
    }
  }, [])

  const children = useMemo(() => {
    if (!promos) return [0, 0, 0].map((_, index) => <PromoCard key={index} />)

    return promos
      .map((promo, index) => {
        if (!promo.resource) return null

        return (
          <PromoCard<PromotesResourceModel>
            autoFocus={shouldAutoFocusPromoCardRef.current && index === 0}
            key={promo.id}
            card={promo}
            onCardPress={handleCardPress}
            onCardFocus={
              onAutoFocus && shouldAutoFocusPromoCardRef.current && index === 0
                ? onAutoFocus
                : undefined
            }
          />
        )
      })
      .filter((p): p is JSX.Element => p !== null)

    // return [
    //   <BannerPromoCard
    //     key={'banner'}
    //     autoFocus={shouldAutoFocusPromoCardRef.current}
    //     onCardFocus={onAutoFocus && shouldAutoFocusPromoCardRef.current ? onAutoFocus : undefined}
    //   />,
    //   ...preparedPromos,
    // ]
  }, [promos, onAutoFocus])
  return (
    <>
      {loading && (
        <Loader>
          <Spinner />
        </Loader>
      )}
      <PromoSlider>{children}</PromoSlider>
    </>
  )
}

export default PromotesResourceSlider
