import { ApiFetchAllWithPaginationPayload, ApiHelper } from '@/core/api/api'
import { ContentModel, ContentType } from '@/models/content.model'
import { MEDIA_API_URL } from '@/core/config'

export type BaseServerResponse = ApiFetchAllWithPaginationPayload<ContentModel>

const SearchService = (api: ApiHelper) => ({
  makeSearch: async (args: SearchArgs) => {
    const { searchData: data, order, paginate = '1:20' } = args
    return api.post<BaseServerResponse>('clients/me/search/contents', {
      baseURL: MEDIA_API_URL,
      data,
      params: {
        relations:
          'age,movie,serial,countries,trailer,partner,posters.verticalImage,posters.horizontalImage,backgrounds.image',
        paginate,
        order,
      },
    })
  },
})

interface SearchPayload {
  title: string
  type: ContentType
  years: string[]
  genreIds: string[]
  countryIds: string[]
  partnerIds: string[]
  tagsIds: string[]
}

export interface SearchArgs {
  searchData: Partial<SearchPayload>
  order?: string
  paginate: string
}

export default SearchService
