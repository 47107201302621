import { EpisodeBaseModel, SeasonBaseModel, SerialModel } from '@/models/serial.model'
import dayjs from 'dayjs'

export const getSeasonEpisodeTitle = (
  episode?: EpisodeBaseModel | null,
  serial?: SerialModel | null,
) => {
  if (!serial || !episode) return ''

  const episodeTitle = `${episode.number} серия`
  const seasonId = episode.seasonId
  const season = serial.serial?.seasons?.find((el) => el.id === seasonId)

  if (!season) return episodeTitle
  const seasonTitle = `${season.number} сезон`

  return `${seasonTitle} ${episodeTitle}`
}

export const getSeasons = (serial?: SerialModel | null, episode?: EpisodeBaseModel | null) => {
  if (!episode || !serial) return
  return serial.serial?.seasons
}

export const getCurrentSeason = (
  serial?: SerialModel | null,
  episode?: EpisodeBaseModel | null,
) => {
  if (!episode || !serial) return

  const seasonId = episode.seasonId
  const seasons = getSeasons(serial, episode)

  const season = seasons?.find((el) => el.id === seasonId)

  if (!season) return

  return season
}

export const getEpisodes = (serial?: SerialModel | null, episode?: EpisodeBaseModel | null) => {
  const season = getCurrentSeason(serial, episode)

  const episodes = season?.episodes

  if (!episodes) return

  return episodes
}

export const getNextSeason = (serial?: SerialModel | null, episode?: EpisodeBaseModel | null) => {
  const seasons = getSeasons(serial, episode)
  if (!episode) return
  const season = getCurrentSeason(serial, episode)
  if (!season) return
  const seasonNumber = season.number

  return seasons?.find((el) => el.number === seasonNumber + 1)
}

export const getPrevSeason = (serial?: SerialModel | null, episode?: EpisodeBaseModel | null) => {
  const seasons = getSeasons(serial, episode)
  if (!episode) return
  const season = getCurrentSeason(serial, episode)
  if (!season) return
  const seasonNumber = season.number

  return seasons?.find((el) => el.number === seasonNumber - 1)
}

export const getFirstEpisodeFromSeason = (season?: SeasonBaseModel) => {
  if (!season) return

  return season.episodes?.[0]
}

export const getLastEpisodeFromSeason = (season?: SeasonBaseModel) => {
  if (!season || !season.episodes) return

  return season.episodes[season.episodes.length - 1]
}

export const getNextEpisodeWithoutReleaseDateCheck = (
  serial?: SerialModel | null,
  episode?: EpisodeBaseModel | null,
) => {
  const episodes = getEpisodes(serial, episode)
  if (!episodes || !episode) return
  const episodeNumber = episode.number

  const episodeIndex = episodes.findIndex(el => el.number === episodeNumber)

  if (episodeIndex === -1) return

  const next = episodes.find((_, index) => index === episodeIndex + 1)

  if (next) {
    return next
  }

  const nextSeason = getNextSeason(serial, episode)

  if (!nextSeason) return

  return getFirstEpisodeFromSeason(nextSeason)
}

export const getNextEpisode = (serial?: SerialModel | null, episode?: EpisodeBaseModel | null) => {
  const episodeWithoutReleaseDateCheck = getNextEpisodeWithoutReleaseDateCheck(serial, episode)

  if (!episodeWithoutReleaseDateCheck) return

  const isNotReleased =
    !!episodeWithoutReleaseDateCheck.releasedAt &&
    dayjs(episodeWithoutReleaseDateCheck.releasedAt).isAfter(dayjs())

  if (isNotReleased) return

  return episodeWithoutReleaseDateCheck
}

export const getPrevEpisode = (serial?: SerialModel | null, episode?: EpisodeBaseModel | null) => {
  const episodes = getEpisodes(serial, episode)
  if (!episodes || !episode) return
  const episodeNumber = episode.number
  const episodeIndex = episodes.findIndex((el) => el.number === episodeNumber)

  if (episodeIndex === -1) return
  const prev = episodes.find((_, index) => index === episodeIndex - 1)

  if (prev) return prev

  const prevSeason = getPrevSeason(serial, episode)

  if (!prevSeason) return

  return getLastEpisodeFromSeason(prevSeason)
}
