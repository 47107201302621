import { createApi } from '@reduxjs/toolkit/query/react'

import {
  ACCESS_TOKEN_KEY,
  IS_NOTIFICATION_SOCKETS_ENABLED,
  NOTIFICATION_API_URL,
} from '@/core/config'
import { apiBaseQuery } from '@/core/api/api.query'

import SocketClient from '@/core/sockets/client'
import { getFromLocalStorage } from '@/core/utils'

import { NotificationModel } from '@/models/notification.model'
import { ClientNotificationSocketEvent } from '@/core/sockets/events'
import {
  notificationShow,
  shouldShowNotification,
} from '@/modules/notifications/notification.helper'

import { SocketUrls } from '@/core/sockets/soketUrls'
import { subscribeToNotificationSocketConnection } from '@/core/sockets/connect.subsciptions'
import {
  connectToTvEventsSockets,
  connectToContentEventsSockets,
} from '@/modules/statistics/statistics.actions'

export const notificationsApi = createApi({
  reducerPath: 'notificationsApi',
  baseQuery: apiBaseQuery({ baseUrl: `${NOTIFICATION_API_URL}/clients/me/` }),
  tagTypes: ['NOTIFICATIONS'],
  endpoints: (builder) => ({
    getAllNotifications: builder.query<NotificationModel[], void>({
      query: () => ({
        url: 'notifications',
        method: 'GET',
      }),
      providesTags: ['NOTIFICATIONS'],
      async onCacheEntryAdded(
        arg,
        { cacheDataLoaded, getCacheEntry, dispatch, updateCachedData, cacheEntryRemoved },
      ) {
        let isFirstRequest = true
        try {
          // wait for the initial query to resolve before proceeding
          await cacheDataLoaded

          if (isFirstRequest) {
            const { data } = await getCacheEntry()

            const isAutoShowNotifications = data?.filter(
              (el) => el.isAutoShow && !el.clientNotification.readedAt,
            )

            isAutoShowNotifications?.forEach((notification) => {
              if (!shouldShowNotification(notification)) return
              notificationShow(notification)
            })

            isFirstRequest = false
          }

          dispatch(connectToTvEventsSockets())
          dispatch(connectToContentEventsSockets())

          if (IS_NOTIFICATION_SOCKETS_ENABLED) {
            const socketClient = SocketClient.getInstance()

            await socketClient.connect(
              SocketUrls.NOTIFICATIONS,
              {
                extraHeaders: { Authorization: `${getFromLocalStorage(ACCESS_TOKEN_KEY)}` },
                transports: ['polling', 'websocket'],
                closeOnBeforeunload: false,
                reconnection: true,
                reconnectionDelay: 1000,
                reconnectionDelayMax: 10000,
                reconnectionAttempts: 5000,
              },
              subscribeToNotificationSocketConnection,
            )

            socketClient.on<NotificationModel>(
              SocketUrls.NOTIFICATIONS,
              ClientNotificationSocketEvent.CREATE,
              (notification) => {
                dispatch(notificationsApi.util.invalidateTags(['NOTIFICATIONS']))

                if (!shouldShowNotification(notification)) return
                notificationShow(notification)
              },
            )

            socketClient.on(
              SocketUrls.NOTIFICATIONS,
              ClientNotificationSocketEvent.DELETE_ONE,
              (notification: NotificationModel) => {
                if (!notification) {
                  dispatch(notificationsApi.util.invalidateTags(['NOTIFICATIONS']))
                  return
                }

                updateCachedData((data) => {
                  return data.filter((el) => {
                    return el.id !== notification.id
                  })
                })
              },
            )

            socketClient.on(
              SocketUrls.NOTIFICATIONS,
              ClientNotificationSocketEvent.DELETE_ALL,
              () => {
                updateCachedData(() => {
                  return []
                })
              },
            )

            socketClient.on(
              SocketUrls.NOTIFICATIONS,
              ClientNotificationSocketEvent.READ_ALL,
              () => {
                updateCachedData((data) => {
                  return data.map((el) => ({
                    ...el,
                    clientNotification: { ...el.clientNotification, readedAt: `${Date.now()}` },
                  }))
                })
              },
            )

            socketClient.on(
              SocketUrls.NOTIFICATIONS,
              ClientNotificationSocketEvent.READ_ONE,
              (notification: NotificationModel) => {
                if (!notification) {
                  dispatch(notificationsApi.util.invalidateTags(['NOTIFICATIONS']))
                  return
                }

                updateCachedData((data) => {
                  return data.map((el) => {
                    return el.id === notification.id
                      ? {
                          ...el,
                          clientNotification: {
                            ...el.clientNotification,
                            readedAt: `${Date.now()}`,
                          },
                        }
                      : el
                  })
                })
              },
            )
          }
        } catch {
          // no-op in case `cacheEntryRemoved` resolves before `cacheDataLoaded`,
          // in which case `cacheDataLoaded` will throw
        }
        // cacheEntryRemoved will resolve when the cache subscription is no longer active
        await cacheEntryRemoved
        // perform cleanup steps once the `cacheEntryRemoved` promise resolves
      },
    }),

    removeNotificationById: builder.mutation<void, RemoveNotificationByIdArgs>({
      query: ({ notificationId }) => ({
        url: `notifications/${notificationId}`,
        method: 'DELETE',
      }),
      invalidatesTags: ['NOTIFICATIONS'],
    }),

    removeAllNotifications: builder.mutation<void, void>({
      query: () => ({
        url: `notifications`,
        method: 'DELETE',
      }),
      invalidatesTags: ['NOTIFICATIONS'],
    }),

    viewNotificationById: builder.mutation<void, RemoveNotificationByIdArgs>({
      query: ({ notificationId }) => ({
        url: `notifications/${notificationId}/view`,
        method: 'PATCH',
      }),
      invalidatesTags: ['NOTIFICATIONS'],
    }),

    readAllNotifications: builder.mutation<void, void>({
      query: () => ({
        url: `notifications/read`,
        method: 'PATCH',
      }),
      invalidatesTags: ['NOTIFICATIONS'],
    }),
  }),
})

interface RemoveNotificationByIdArgs {
  notificationId: string
}

export const {
  useGetAllNotificationsQuery,
  useViewNotificationByIdMutation,
  useRemoveNotificationByIdMutation,
  useRemoveAllNotificationsMutation,
  useReadAllNotificationsMutation,
} = notificationsApi
