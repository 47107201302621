import { createApi } from '@reduxjs/toolkit/query/react'

import { CHANNELS_API_URL } from '@/core/config'
import { apiBaseQuery } from '@/core/api/api.query'

import { StreamsModel } from '@/models/streams.model'

export const streamsChannelsApi = createApi({
  reducerPath: 'streamsChannelsApi',
  baseQuery: apiBaseQuery({ baseUrl: `${CHANNELS_API_URL}/clients/me/streams` }),
  tagTypes: [],
  endpoints: (builder) => ({
    getStreamsByChannelId: builder.query<StreamsModel[], { channelId: string; token?: string }>({
      query: ({ channelId, token }) => {
        return {
          url: `/channels/${channelId}`,
          method: 'GET',
          headers: {
            StreamAccessToken: token,
          },
        }
      },
    }),

    getStreamsByChannelIdAndProgramId: builder.query<
      StreamsModel[],
      { channelId: string; programId: string; token?: string }
    >({
      query: ({ channelId, programId, token }) => {
        return {
          url: `/channels/${channelId}/programs/${programId}`,
          method: 'GET',
          headers: {
            StreamAccessToken: token,
          },
        }
      },
    }),
  }),
})

export const {
  useLazyGetStreamsByChannelIdQuery,
  useGetStreamsByChannelIdAndProgramIdQuery,
  useLazyGetStreamsByChannelIdAndProgramIdQuery,
} = streamsChannelsApi
