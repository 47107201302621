import React, { FC, Fragment } from 'react'

import classNames from 'classnames'
import styles from './styles.module.scss'

import List, { ListProps } from '@/components/List'
import ContentCard from '@/modules/content/components/ContentCard'
import LoadMoreSearchResults from '@/modules/search/components/LoadMoreSearchResults'
import FirstLineOfGridWithObserver from '@/components/FirstLineOfGridWithObserver'
import NavigateToFirstElementOfListButton from '@/components/NavigateToFirstElementOfListButton'

import { ContentModel } from '@/models/content.model'

interface SearchResultsListProps extends Omit<ListProps, 'children'> {
  searchResult: ContentModel[]
  shouldShowLoadMore: boolean
  onCardPress?: (item: ContentModel) => void
  onLoadMorePress?: () => void
}

const SearchResultsList: FC<SearchResultsListProps> = ({
  searchResult,
  onCardPress,
  onLoadMorePress,
  shouldShowLoadMore,
  ...listProps
}) => {
  return (
    <>
      <List
        dynamicLength
        focusProps={{ focusKey: 'search-results-list' }}
        {...listProps}
        scrollWrapperClassName={classNames(listProps.className, styles.List)}
      >
        {[
          <FirstLineOfGridWithObserver
            listFocusKey={'search-results-list'}
            key={'first-hidden-element-of-search-results-list'}
          />,
          ...searchResult.map((el) => {
            return (
              <ContentCard
                focusProps={{ focusKey: el.id }}
                onCardPress={onCardPress}
                wrapperClassName={classNames(styles.Card)}
                key={el.id}
                card={el}
              />
            )
          }),
          !shouldShowLoadMore ? (
            <Fragment key={'empty_fragment'} />
          ) : (
            <LoadMoreSearchResults key={'LoadMoreButton'} onLoadMorePress={onLoadMorePress} />
          ),
        ]}
      </List>
      <NavigateToFirstElementOfListButton
        firstElement={searchResult[0]}
        listFocusKey={'search-results-list'}
      />
    </>
  )
}
export default SearchResultsList
