import React, { useCallback, useEffect, useMemo, useRef } from 'react'

import styles from './styles.module.scss'

import Loader from '@/components/Loader'
import List from '@/components/List'
import FirstLineOfGridWithObserver from '@/components/FirstLineOfGridWithObserver'
import NavigateToFirstElementOfListButton from '@/components/NavigateToFirstElementOfListButton'
import TvChannelCard from '@/modules/tv/components/TvChannelCard'
import EmptyFavorites from '@/modules/favorites/components/EmptyFavorites'

import { useAppDispatch, useAppSelector } from '@/core/store'
import { useCustomRef } from '@/core/hooks/useCustomRef'
import { dispatchRequestPlayerEnterToFullscreenEvent } from '@/core/customEvents/player.custom.events'
import { sortCompareFunction } from '@/core/sort'

import { useGetAllChannelsQuery } from '@/modules/tv/tv.api'
import {
  resetChosenCategory,
  resetChosenChannel,
  resetCurrentProgram,
  setChosenChannel,
  setFocusedChannel,
} from '@/modules/tv/tv.actions'
import {
  favoritesChannelsAdapter,
  favoritesChannelsSelectors,
  useGetAllMeFavoritesChannelsQuery,
} from '@/modules/favorites/favorites.channels.api'

import { ChannelModel } from '@/models/channel.model'
import { OnChildFocus } from '@/components/Slider/DefaultScrollingWrapper'
import { UseFocusableConfig } from '@noriginmedia/norigin-spatial-navigation'
import type { InitListActions } from '@/components/List'

const ChannelsList = () => {
  const dispatch = useAppDispatch()

  const shouldSetChannel = useRef(true)

  const { data, isLoading } = useGetAllChannelsQuery({})
  const {
    data: favorites,
    // isLoading: loadingFavorites,
    // isFetching: fetchingFavorites,
  } = useGetAllMeFavoritesChannelsQuery(undefined, {
    selectFromResult: ({ data, ...rest }) => {
      return {
        data: favoritesChannelsSelectors.selectAll(
          data ?? favoritesChannelsAdapter.getInitialState(),
        ),
        ...rest,
      }
    },
  })

  const { 0: listActions, 1: setActions } = useCustomRef<InitListActions>({})

  const chosenCategoryId = useAppSelector((state) => state.tv.chosenCategory?.id)
  const chosenChannel = useAppSelector((state) => state.tv.chosenChannel)

  const resetChannelsListPosition = useCallback(() => {
    if (!listActions.current.reset) return
    listActions.current.reset()
  }, [])

  const preparedData = useMemo(() => {
    if (!data) return
    resetChannelsListPosition()
    if (!chosenCategoryId) return data

    if (chosenCategoryId === 'favorites') {
      return data.filter((channel) => {
        return !!favorites.find((favoriteChannel) => channel.id === favoriteChannel.id)
      })
    }

    return data
      .filter((channel) => {
        const channelCategories = channel.categories
        if (!channelCategories) return false

        return channelCategories.map((cat) => cat.id).includes(chosenCategoryId)
      })
      .map((channel) => ({
        ...channel,
        // if chosenCategoryId defined change channel order to specific order from channelsCategories table
        order:
          channel.channelsCategories?.find(
            (channelCategory) => channelCategory.categoryId === chosenCategoryId,
          )?.order ?? channel.order,
      }))
      .sort(
        // sort by new order
        sortCompareFunction<ChannelModel>({
          ascending: true,
          sortBy: 'order',
        }),
      )
  }, [chosenCategoryId, data, favorites])

  useEffect(() => {
    if (!data || !shouldSetChannel.current) return
    if (chosenChannel) return
    const firstChannel = data[0]
    dispatch(setChosenChannel(firstChannel))
  }, [data])

  useEffect(() => {
    return () => {
      dispatch(resetChosenChannel())
      dispatch(resetChosenCategory())
    }
  }, [])
  const timeoutId = useRef<ReturnType<typeof setTimeout> | null>(null)

  const handleListBlur = useCallback(() => {
    if (timeoutId.current) {
      clearTimeout(timeoutId.current)
    }
    if (!chosenChannel) return
    dispatch(setFocusedChannel(chosenChannel))
  }, [chosenChannel])

  const handleChannelCardPress = useCallback(
    (channel: ChannelModel) => {
      if (channel.id === chosenChannel?.id) {
        dispatchRequestPlayerEnterToFullscreenEvent()
        return
      }
      dispatch(setChosenChannel(channel))
      dispatch(resetCurrentProgram())
    },
    [chosenChannel],
  )

  const handleChannelCardFocus: OnChildFocus<ChannelModel> = useCallback((...args) => {
    const channel = args[4]
    if (!channel) return
    if (timeoutId.current) {
      clearTimeout(timeoutId.current)
    }
    const id = setTimeout(() => {
      dispatch(setFocusedChannel(channel))
      clearTimeout(id)
      timeoutId.current = null
    }, 1000)

    timeoutId.current = id
  }, [])

  const focusProps: UseFocusableConfig = useMemo(() => {
    return {
      focusKey: 'channels-list',
      onBlur: handleListBlur,
    }
  }, [chosenChannel])

  return (
    <div className={styles.ChannelsListWrapper}>
      {(isLoading || !preparedData) && (
        <Loader>
          <Loader.Spinner />
        </Loader>
      )}

      {chosenCategoryId &&
        chosenCategoryId === 'favorites' &&
        preparedData &&
        preparedData.length === 0 && (
          <EmptyFavorites
            text={'Здесь будут отображаться Ваши избранные каналы'}
            className={styles.EmptyFavorites}
          />
        )}

      {preparedData && !!preparedData.length && (
        <>
          <List
            className={styles.ChannelsList}
            scrollWrapperClassName={styles.ContentWrapper}
            focusProps={focusProps}
            onInit={setActions}
          >
            {[
              <FirstLineOfGridWithObserver
                listFocusKey={'channels-list'}
                key={'firstLineOfGridWithObserver'}
              />,
              ...preparedData.map((el) => {
                return (
                  <TvChannelCard
                    key={el.id}
                    focusProps={{ focusKey: el.id }}
                    card={el}
                    wrapperClassName={styles.ChannelCard}
                    onCardPress={handleChannelCardPress}
                    onCardFocus={handleChannelCardFocus}
                  />
                )
              }),
            ]}
          </List>
          <NavigateToFirstElementOfListButton
            firstElement={preparedData[0]}
            listFocusKey={'channels-list'}
          />
        </>
      )}
    </div>
  )
}

export default ChannelsList
