import React, { useCallback, useEffect, useMemo, useRef } from 'react'

import styles from './styles.module.scss'

import Slider from '@/components/Slider'
import ProgramDatesCard from '@/modules/tv/components/ProgramDatesCard'

import { useAppDispatch, useAppSelector } from '@/core/store'

import {
  GetDatesByChannelIdResponseItem,
  GetDatesByChannelIdResponseItemStatus,
} from '@/modules/tv/tv.types'
import { useGetDatesByChannelIdQuery } from '@/modules/tv/tv.api'
import { setChosenDate, setCurrentDate } from '@/modules/tv/tv.program.slice'
import { formatDateToDayMonthYearString } from '@/modules/tv/components/ProgramDatesCard/programDatesCard.helper'

const ProgramDatesSlider = () => {
  const dispatch = useAppDispatch()

  const shouldHandleKeyUp = useRef(false)

  const sliderFocusProps = {
    preferredChildFocusKey: 'today',
    onFocus: () => {
      shouldHandleKeyUp.current = false
    },
    onBlur: () => {
      shouldHandleKeyUp.current = true
    },
  }

  const chosenDate = useAppSelector((state) => state.tvProgram.chosenDate)
  const chosenChannel = useAppSelector((state) => state.tv.chosenChannel)
  const currentProgram = useAppSelector((state) => state.tv.currentProgram)

  const chosenDateRef = useRef(chosenDate)
  chosenDateRef.current = chosenDate

  const { data, isLoading } = useGetDatesByChannelIdQuery({ channelId: chosenChannel!.id })
  const datesRef = useRef(data)
  datesRef.current = data

  const handleDatePress = useCallback((date: GetDatesByChannelIdResponseItem) => {
    dispatch(setChosenDate(date))
  }, [])

  const shouldSetCurdDate = useRef(true)

  useEffect(() => {
    if (!data || !shouldSetCurdDate.current) return

    data.forEach((el) => {
      if (el.status === GetDatesByChannelIdResponseItemStatus.CURRENT) {
        dispatch(setCurrentDate(el))
        dispatch(setChosenDate(el))
      }
    })

    shouldSetCurdDate.current = false
  }, [data])

  const formattedCurrentProgramTime = useMemo(() => {
    if (!currentProgram) return

    return formatDateToDayMonthYearString(new Date(currentProgram.startTime))
  }, [currentProgram])

  const preparedData = useMemo(() => {
    if (!data) return
    return data.map((el, index) => {
      if (el.status === GetDatesByChannelIdResponseItemStatus.CURRENT) {
        // handleSetCurrentDate(el)
      }
      const isActive =
        formattedCurrentProgramTime &&
        el &&
        formattedCurrentProgramTime === formatDateToDayMonthYearString(new Date(el.date))
      return (
        <ProgramDatesCard
          focusProps={{
            focusKey: isActive ? 'today' : undefined,
          }}
          key={index}
          card={el}
          autoFocus={!!isActive}
          onCardPress={handleDatePress}
          onCardFocus={() => handleDatePress(el)}
          // isActive={!!isActive || el.date === chosenDate?.date}
          isActive={!!isActive}
          isChosen={el.date === chosenDate?.date}
        />
      )
    })
  }, [data, chosenDate, formattedCurrentProgramTime])

  useEffect(() => {
    const listener = (e: KeyboardEvent) => {
      if (!shouldHandleKeyUp.current) return

      const dates = datesRef.current
      const chosenDate = chosenDateRef.current

      if (!dates || !chosenDate) return
      if (e.key === 'ArrowRight') {
        e.stopPropagation()
        e.preventDefault()

        const index = dates.indexOf(chosenDate)
        const nextIndex = dates.length - 1 > index ? index + 1 : 0

        const nextDate = dates[nextIndex]
        if (!nextDate) return

        dispatch(setChosenDate(nextDate))
      }

      if (e.key === 'ArrowLeft') {
        e.stopPropagation()
        e.preventDefault()

        const index = dates.indexOf(chosenDate)
        const nextIndex = index > 0 ? index - 1 : dates.length - 1

        const nextDate = dates[nextIndex]

        dispatch(setChosenDate(nextDate))
      }
    }
    document.addEventListener('keyup', listener)

    return () => {
      document.removeEventListener('keyup', listener)
    }
  }, [])

  if (!preparedData || isLoading) return null

  return (
    <Slider className={styles.ProgramDatesSliderFocusWrapper} focusProps={sliderFocusProps}>
      <Slider.Horizontal
        className={styles.ProgramDatesSliderScrollingWrapper}
        contentWrapperClassName={styles.ProgramDatesSliderContentWrapper}
      >
        {preparedData}
      </Slider.Horizontal>
    </Slider>
  )
}

export default ProgramDatesSlider
