import React, { memo, useCallback, useMemo, useRef } from 'react'

import styles from '@/layouts/content/styles.module.scss'

import ChosenContent from '@/modules/content/components/ChosenContent'
import FetchAndRenderPromotesContent from '@/modules/promotes/components/FetchAndRenderPromotesContent'

import { useAppSelector } from '@/core/store'

import { useLocation } from 'react-router-dom'

import { ContentPaths } from '@/core/router/router.paths'

const ContentLayoutHeader = () => {
  const card = useAppSelector((state) => state.content.chosenCard)

  const shouldAutoFocusPromoRef = useRef(true)

  const handleOnAutoFocus = useCallback(() => {
    shouldAutoFocusPromoRef.current = false
  }, [])

  return (
    <div className={styles.ContentLayout__Header}>
      {card ? (
        <ChosenContent />
      ) : (
        <ContentPromotes
          shouldAutoFocusPromo={shouldAutoFocusPromoRef.current}
          onAutoFocus={handleOnAutoFocus}
        />
      )}
    </div>
  )
}

interface ContentPromotesProps {
  shouldAutoFocusPromo: boolean
  onAutoFocus: () => void
}

const ContentPromotes = ({ shouldAutoFocusPromo, onAutoFocus }: ContentPromotesProps) => {
  const { pathname } = useLocation()

  const contentType = useMemo(() => {
    return pathname.includes(ContentPaths.MOVIES)
      ? 'movies'
      : pathname.includes(ContentPaths.SERIALS)
      ? 'serials'
      : 'resources'
  }, [pathname])

  return (
    <FetchAndRenderPromotesContent
      contentType={contentType}
      shouldAutoFocusPromoCard={shouldAutoFocusPromo}
      onAutoFocus={onAutoFocus}
    />
  )
}

export default memo(ContentLayoutHeader)
