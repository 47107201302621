import React, { FC } from 'react'

import { getActorsFromContent, getGenresFromContent } from '@/modules/content/content.helpers'

import { useAppSelector } from '@/core/store'

interface ActorsAndGenresProps {
  className?: string
}

const ActorsAndGenres: FC<ActorsAndGenresProps> = ({ className }) => {
  const card = useAppSelector((state) => state.content.chosenCard)
  return (
    <>
      <div
        className={className}
        style={{
          minHeight: '95px',
        }}
      >
        <b style={{ fontSize: '26px' }}>Актерский состав:</b> <br />{' '}
        {getActorsFromContent(card) || 'Нет информации'}
      </div>
      <div
        className={className}
        style={{
          minHeight: '95px',
        }}
      >
        <b style={{ fontSize: '26px' }}>Жанры:</b> <br />{' '}
        {getGenresFromContent(card) || 'Нет информации'}
      </div>
    </>
  )
}

export default ActorsAndGenres
