import React, { FC } from 'react'

import classNames from 'classnames'
import styles from './styles.module.scss'

import { useAppDispatch, useAppSelector } from '@/core/store'

import Button from '@/components/Button'

import {
  favoritesContentAdapter,
  favoritesContentSelectors,
  useAddContentToFavoritesMutation,
  useGetAllFavoritesContentQuery,
  useRemoveContentFromFavoritesMutation,
} from '@/modules/favorites/favorites.media.api'

import { ContentType } from '@/models/content.model'
import { feedsApi } from '@/modules/feeds/feeds.api'

interface FavoritesActionProps {
  withText?: boolean
  disabled?: boolean
  className?: string
  onFavorites?: () => void
  favoritesContentType?: ContentType
}

const FavoritesAction: FC<FavoritesActionProps> = ({
  className,
  favoritesContentType,
  disabled,
  onFavorites,
  withText = false,
}) => {
  const dispatch = useAppDispatch()

  const card = useAppSelector((state) => state.content.chosenCard)
  const profile = useAppSelector((state) => state.profile.data.profile)

  const {
    data: favoritesItem,
    isLoading: loadingFavorites,
    isFetching: fetchingFavorites,
  } = useGetAllFavoritesContentQuery(
    { type: favoritesContentType },
    {
      skip: !card || !card.id || !profile,
      selectFromResult: ({ data, ...rest }) => ({
        data: favoritesContentSelectors.selectById(
          data ?? favoritesContentAdapter.getInitialState(),
          card?.id || '',
        ),
        ...rest,
      }),
    },
  )

  const [addToFavorites, { isLoading: addingToFavorites }] = useAddContentToFavoritesMutation()
  const [removeFromFavorites, { isLoading: removingFromFavorites }] =
    useRemoveContentFromFavoritesMutation()

  const handleOnFavorites = () => {
    onFavorites?.()

    const id = card?.id
    if (!id) return
    if (favoritesItem) {
      removeFromFavorites({ id })
        .unwrap()
        .then(() => {
          dispatch(feedsApi.util.invalidateTags(['FAVORITE_CONTENT_RESOURCES']))
        })
      return
    }
    addToFavorites({ id })
      .unwrap()
      .then(() => {
        dispatch(feedsApi.util.invalidateTags(['FAVORITE_CONTENT_RESOURCES']))
      })
  }

  if (!card || !profile) return null
  return (
    <Button
      onButtonClick={handleOnFavorites}
      onEnterPress={handleOnFavorites}
      disabled={
        disabled ||
        loadingFavorites ||
        fetchingFavorites ||
        addingToFavorites ||
        removingFromFavorites
      }
      buttonClassName={classNames(styles.FavoritesAction, className)}
    >
      <>
        <i className={classNames('icon-fill-heart', favoritesItem && styles.Icon__Active)} />
        {withText && (
          <span data-title={'title'} className={styles.Text}>
            {favoritesItem ? 'Удалить из избранного' : 'Добавить в избранное'}
          </span>
        )}
      </>
    </Button>
  )
}

export default FavoritesAction
