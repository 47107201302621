import React, { useCallback, useEffect, useMemo, useRef } from 'react'

import classNames from 'classnames'
import styles from './styles.module.scss'

import Loader from '@/components/Loader'
import ContentPlayer from '@/modules/content/components/ContentPlayer'
import ContentPlayerTitle from '@/modules/content/components/ContentPlayerTitle'
import SerialsPlayerOverlayActions from '@/modules/serials/component/SerialsPlayerOverlayActions'

import { useAppDispatch, useAppSelector } from '@/core/store'
import { DRM_CONFIG } from '@/core/drm.config'

import { getNextEpisode, getSeasonEpisodeTitle } from '@/modules/serials/serials.helpers'
import { setEpisode } from '@/modules/serials/serials.actions'

import { useContentPlayerRequestExitFullscreen } from '@/modules/content/hooks/useContentPlayerRequestFullscreen'

import { LeftSlotRenderFn } from '@/modules/player/components/player/Overlay'
import { PlayerProps } from '@/modules/player/Player'
import { useGetOneContentMediaViewQuery } from '@/modules/syncMediaView/syncMediaView.api'
import { getProgressState } from '@/modules/syncMediaView/helpers'
import { PartnerValue } from '@/models/partner.model'
import StreamsErrorFallback from '@/components/StreamsErrorFallback'
import { isObject } from '@/models/rightsError.model'
import FetchUnloadedSeasonsEpisodes from '@/modules/serials/component/FetchUnloadedSeasonsEpisodes'
import {
  useGetPartnersPathBySerialIdAndByEpisodeIdV2Query,
  useGetStreamsBySerialIdAndByEpisodeIdV2Query,
} from '@/modules/streams/streams.media.api'

const SerialPlayerFullscreen = () => {
  const dispatch = useAppDispatch()

  const episode = useAppSelector((state) => state.serial.episode)
  const serial = useAppSelector((state) => state.content.chosenCard)
  const seasons = useAppSelector((state) => state.serial.seasons)
  const episodes = useAppSelector((state) => state.serial.episodes)

  const episodeRef = useRef(episode)

  const {
    data: viewInfo,
    isLoading: contentMediaViewLoading,
    isFetching: contentMediaViewFetching,
  } = useGetOneContentMediaViewQuery(
    { contentId: serial?.id || '' },
    {
      skip: !serial,
      refetchOnMountOrArgChange: true,
    },
  )

  const isContentMediaLoading = useMemo(() => {
    return contentMediaViewFetching || contentMediaViewLoading
  }, [contentMediaViewLoading, contentMediaViewFetching])

  const progressState = useMemo(() => {
    if (isContentMediaLoading) return
    if (!viewInfo) return
    if (episodeRef.current?.id !== viewInfo.resourceId) return

    return getProgressState(viewInfo)
  }, [viewInfo, isContentMediaLoading])

  const {
    data: streams,
    isLoading: streamsLoading,
    isFetching: streamsFetching,
    error: streamsError,
  } = useGetStreamsBySerialIdAndByEpisodeIdV2Query(
    {
      episodeId: episode?.id || '',
      serialId: episode?.serialId || '',
    },
    {
      skip: !episode || !episode.id || !episode.serialId || !serial,
    },
  )

  const {
    data: paths,
    isLoading: pathsLoading,
    isFetching: pathsFetching,
  } = useGetPartnersPathBySerialIdAndByEpisodeIdV2Query(
    {
      episodeId: episode?.id || '',
      serialId: episode?.serialId || '',
    },
    {
      skip: !episode || !episode.id || !serial || serial.partner?.type !== PartnerValue.PREMIER,
    },
  )

  const resolved404Error = useMemo(() => {
    if (!streamsError) return false

    if (!isObject(streamsError)) return false

    if (!('status' in streamsError && streamsError.status === 404)) return false

    return true
  }, [streamsError])

  useEffect(() => {
    episodeRef.current = episode
  }, [episode])

  const { navigateToPreviousPage, navigateByDelta } = useContentPlayerRequestExitFullscreen()

  const handleOnBackButton = useCallback(() => {
    if (resolved404Error) {
      navigateByDelta(2)
      return
    }

    navigateToPreviousPage()
  }, [streamsError, resolved404Error])

  const handleOnEnded = useCallback(() => {
    const episode = episodeRef.current
    const nextEpisode = getNextEpisode(seasons, episodes, episode)

    if (nextEpisode) {
      dispatch(setEpisode(nextEpisode))
      return
    }

    navigateToPreviousPage()
  }, [seasons, episodes])

  const leftSlotRenderFn: LeftSlotRenderFn = useCallback(
    (isPaused, togglePlayPause, changeProgress) => {
      return (
        <SerialsPlayerOverlayActions
          isPaused={isPaused}
          togglePlayPause={togglePlayPause}
          changeProgress={changeProgress}
        />
      )
    },
    [],
  )

  const headerSlotRenderFn = useCallback(() => {
    return (
      <ContentPlayerTitle
        title={
          <>
            <div>{serial?.title}</div>
            <div className={styles.SerialSubTitle}>{`${getSeasonEpisodeTitle(
              episode,
              seasons,
            )}`}</div>
          </>
        }
      />
    )
  }, [episode, seasons])

  const overlayProps = useMemo(() => {
    return {
      leftSlotRenderFn: leftSlotRenderFn,
      headerSlotRenderFn: headerSlotRenderFn,
    }
  }, [headerSlotRenderFn])

  const loading = useMemo(() => {
    return (
      streamsLoading || streamsFetching || isContentMediaLoading || pathsLoading || pathsFetching
    )
  }, [streamsLoading, streamsFetching, isContentMediaLoading])

  const playerProperties: Partial<PlayerProps> = useMemo(() => {
    return {
      playerId: 'SERIAL_PLAYER',
      className: classNames(styles.SerialsPlayer),
      overlayProps,
      videoManagerProps: {
        onEnded: handleOnEnded,
      },
      progressState,
      fullscreenState: { status: true },
      videoStreamerProps: {
        autoPlay: true,
        url: '',
      },
    }
  }, [overlayProps, loading, progressState])

  return (
    <div className={styles.SerialsPlayerPage}>
      <StreamsErrorFallback error={!!streamsError} onBack={handleOnBackButton} />
      {loading && (
        <Loader>
          <Loader.Spinner />
        </Loader>
      )}
      {!isContentMediaLoading && streams && serial && (
        <>
          <ContentPlayer
            url={streams && !streamsLoading && !streamsFetching ? streams : undefined}
            playerId={'SERIAL_PLAYER'}
            drm={serial?.partner?.type === PartnerValue.AMEDIATEKA ? DRM_CONFIG : undefined}
            playerProps={playerProperties}
            content={serial}
            episode={episode || undefined}
            viewInfo={viewInfo}
            partnerPlaybackPath={paths?.partnerPlaybackPath}
          />
          <FetchUnloadedSeasonsEpisodes />
        </>
      )}
    </div>
  )
}

export default SerialPlayerFullscreen
