import { createApi } from '@reduxjs/toolkit/query/react'

import { API_URL, MEDIA_API_URL, CHANNELS_API_URL } from '@/core/config'
import { apiBaseQuery } from '@/core/api/api.query'
import { FeedsTemplateModel } from '@/models/feeds.template.model'
import { ApiFetchAllWithPaginationPayload } from '@/core/api/api'
import { ContentModel, ContentWithViewModel } from '@/models/content.model'
import { ChannelModel } from '@/models/channel.model'
import { GenreModel } from '@/models/genre.model'
import { sortCompareFunction } from '@/core/sort'

export const feedsApi = createApi({
  reducerPath: 'feedsApi',
  baseQuery: apiBaseQuery({ baseUrl: `${API_URL}` }),
  tagTypes: [
    'TEMPLATES',
    'CONTENT_RESOURCES',
    'FAVORITE_CONTENT_RESOURCES',
    'CHANNEL-RESOURCES',
    'FAVORITE-CHANNEL-RESOURCES',
    'GENRES-RESOURCES',
    'VIEWED-RESOURCES',
  ],
  endpoints: (builder) => ({
    getFeedsTemplatesByGroupType: builder.query<FeedsTemplateModel, GetFeedsTemplatesArguments>({
      query: ({ params }) => ({
        url: '/clients/me/feeds/templates',
        method: 'GET',
        params,
      }),
      providesTags: ['TEMPLATES'],
    }),

    getContentByResourceUrl: builder.query<ContentModel[], GetContentByResourceUrlArgs>({
      query: ({ url, paginate }) => ({
        url: `${url}`,
        method: 'GET',
        params: {
          paginate: paginate || '1:15', // !!!important for fast render!!!
          relations:
            'age,partner,countries,posters.verticalImage,posters.horizontalImage,backgrounds.image',
        },
        baseUrl: MEDIA_API_URL,
      }),
      transformResponse: (response: ApiFetchAllWithPaginationPayload<ContentModel>) => {
        return response.items
      },
      providesTags: ['CONTENT_RESOURCES'],
    }),

    getFavoriteContentByResourceUrl: builder.query<ContentModel[], GetContentByResourceUrlArgs>({
      query: ({ url, paginate }) => ({
        url: `${url}`,
        method: 'GET',
        params: {
          paginate: paginate || '1:15', // !!!important for fast render!!!
          relations:
            'age,partner,countries,posters.verticalImage,posters.horizontalImage,backgrounds.image',
        },
        baseUrl: MEDIA_API_URL,
      }),
      transformResponse: (response: ApiFetchAllWithPaginationPayload<ContentModel>) => {
        return response.items
      },
      providesTags: ['FAVORITE_CONTENT_RESOURCES'],
    }),

    getViewedContentByResourceUrl: builder.query<
      ContentWithViewModel[],
      GetContentByResourceUrlArgs
    >({
      query: ({ url, paginate }) => ({
        url: `${url}`,
        method: 'GET',
        params: {
          paginate: paginate || '1:15', // !!!important for fast render!!!
          // relations: 'posters.verticalImage,posters.horizontalImage,backgrounds.image',
          relations:
            'age,partner,countries,posters.verticalImage,posters.horizontalImage,backgrounds.image',
        },
        baseUrl: MEDIA_API_URL,
      }),
      transformResponse: (response: ApiFetchAllWithPaginationPayload<ContentWithViewModel>) => {
        return response.items.sort(sortCompareFunction({ sortBy: (val) => val.view.updatedAt }))
      },
      providesTags: ['VIEWED-RESOURCES'],
    }),

    getChannelsByResourceUrl: builder.query<ChannelModel[], GetContentByResourceUrlArgs>({
      query: ({ url }) => ({
        url: `${url}`,
        method: 'GET',
        params: {
          relations: 'logo,categories',
        },
        baseUrl: CHANNELS_API_URL,
      }),
      providesTags: ['CHANNEL-RESOURCES'],
    }),

    getFavoriteChannelsByResourceUrl: builder.query<ChannelModel[], GetContentByResourceUrlArgs>({
      query: ({ url }) => ({
        url: `${url}`,
        method: 'GET',
        params: {
          relations: 'logo,categories',
        },
        baseUrl: CHANNELS_API_URL,
      }),
      providesTags: ['FAVORITE-CHANNEL-RESOURCES'],
    }),

    getGenresByResourceUrl: builder.query<GenreModel[], GetContentByResourceUrlArgs>({
      query: ({ url }) => ({
        url: `${url}`,
        method: 'GET',
        params: {
          relations: 'logo',
        },
        baseUrl: MEDIA_API_URL,
      }),
      providesTags: ['GENRES-RESOURCES'],
    }),
  }),
})

interface GetContentByResourceUrlArgs {
  url: string
  paginate?: string
}

export enum GroupTypes {
  CONTENT = 'CONTENT',
  MOVIE = 'MOVIE',
  SERIAL = 'SERIAL',
}

export interface GetFeedsTemplatesParams {
  groupType: GroupTypes
  slug?: string
}

export interface GetFeedsTemplatesArguments {
  params: GetFeedsTemplatesParams
}

export const {
  useGetFeedsTemplatesByGroupTypeQuery,
  useGetContentByResourceUrlQuery,
  useLazyGetContentByResourceUrlQuery,
  useLazyGetFavoriteContentByResourceUrlQuery,
  useGetChannelsByResourceUrlQuery,
  useLazyGetChannelsByResourceUrlQuery,
  useGetGenresByResourceUrlQuery,
  useLazyGetGenresByResourceUrlQuery,
  useLazyGetFavoriteChannelsByResourceUrlQuery,
  useLazyGetViewedContentByResourceUrlQuery,
} = feedsApi
