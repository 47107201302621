import React, { FC } from 'react'

import classNames from 'classnames'
import styles from './styles.module.scss'

import { useAppDispatch, useAppSelector } from '@/core/store'

import Button from '@/components/Button'

import {
  favoritesChannelsAdapter,
  favoritesChannelsSelectors,
  useAddChannelToFavoritesMutation,
  useGetAllMeFavoritesChannelsQuery,
  useRemoveChannelFromFavoritesMutation,
} from '@/modules/favorites/favorites.channels.api'
import { feedsApi } from '@/modules/feeds/feeds.api'

interface FavoritesChannelsActionProps {
  withText?: boolean
  disabled?: boolean
  className?: string
  onFavorites?: () => void
}

const FavoritesChannelsAction: FC<FavoritesChannelsActionProps> = ({
  className,
  disabled,
  onFavorites,
  withText = false,
}) => {
  const dispatch = useAppDispatch()

  const channel = useAppSelector((state) => state.tv.chosenChannel)
  const profile = useAppSelector((state) => state.profile.data.profile)

  const {
    data: favoritesItem,
    isLoading: loadingFavorites,
    isFetching: fetchingFavorites,
  } = useGetAllMeFavoritesChannelsQuery(undefined, {
    skip: !channel || !channel.id || !profile,
    selectFromResult: ({ data, ...rest }) => {
      return {
        data: favoritesChannelsSelectors.selectById(
          data ?? favoritesChannelsAdapter.getInitialState(),
          channel?.id || '',
        ),
        ...rest,
      }
    },
  })

  const [addToFavorites, { isLoading: addingToFavorites }] = useAddChannelToFavoritesMutation()
  const [removeFromFavorites, { isLoading: removingFromFavorites }] =
    useRemoveChannelFromFavoritesMutation()

  const handleOnFavorites = () => {
    onFavorites?.()

    const id = channel?.id
    if (!id) return
    if (favoritesItem) {
      removeFromFavorites({ id })
        .unwrap()
        .then(() => {
          dispatch(feedsApi.util.invalidateTags(['FAVORITE-CHANNEL-RESOURCES']))
        })
      return
    }
    addToFavorites({ id })
      .unwrap()
      .then(() => {
        dispatch(feedsApi.util.invalidateTags(['FAVORITE-CHANNEL-RESOURCES']))
      })
  }
  if (!channel || !profile) return null
  return (
    <Button
      onButtonClick={handleOnFavorites}
      onEnterPress={handleOnFavorites}
      disabled={
        disabled ||
        loadingFavorites ||
        fetchingFavorites ||
        addingToFavorites ||
        removingFromFavorites
      }
      buttonClassName={classNames(styles.FavoritesAction, className)}
    >
      <>
        <i className={classNames('icon-fill-heart', favoritesItem && styles.Icon__Active)} />
        {withText && (
          <span data-title={'title'} className={styles.Text}>
            {favoritesItem ? 'Удалить из избранного' : 'Добавить в избранное'}
          </span>
        )}
      </>
    </Button>
  )
}

export default FavoritesChannelsAction
