import { createSlice, EntityState } from '@reduxjs/toolkit'
import {
  resetChosenCategory,
  resetChosenChannel,
  resetCurrentProgram,
  resetLiveProgram,
  setChosenCategory,
  setChosenChannel,
  setCurrentProgram,
  setFocusedChannel,
  setLive,
  setLiveProgram,
  setLiveState,
  setTvPlayerFullscreen,
} from '@/modules/tv/tv.actions'
import { CategoryModel } from '@/models/category.model'
import { ChannelModel } from '@/models/channel.model'
import { ProgramBaseModel } from '@/models/program.model'

interface TvSlice {
  playerFS: boolean
  chosenCategory: CategoryModel | null
  chosenChannel: ChannelModel | null
  focusedChannel: ChannelModel | null
  isLive: boolean
  currentProgram: ProgramBaseModel | null
  liveProgram: ProgramBaseModel | null
  liveState: EntityState<ProgramBaseModel> | null
}

const initialState: TvSlice = {
  chosenChannel: null,
  focusedChannel: null,
  playerFS: false,
  chosenCategory: null,
  isLive: true,
  currentProgram: null,
  liveProgram: null,
  liveState: null,
}

export const tvSlice = createSlice({
  name: 'tvSlice',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(setLiveState, (state, action) => {
        state.liveState = action.payload
      })
      .addCase(setTvPlayerFullscreen, (state, action) => {
        state.playerFS = action.payload
      })
      .addCase(setChosenCategory, (state, action) => {
        state.chosenCategory = action.payload
      })
      .addCase(resetChosenCategory, (state) => {
        state.chosenCategory = null
      })
      .addCase(setChosenChannel, (state, action) => {
        state.chosenChannel = action.payload
        state.focusedChannel = action.payload
      })
      .addCase(resetChosenChannel, (state) => {
        state.chosenChannel = null
        state.focusedChannel = null
      })
      .addCase(setFocusedChannel, (state, action) => {
        state.focusedChannel = action.payload
      })

      .addCase(setCurrentProgram, (state, action) => {
        state.currentProgram = action.payload
        state.isLive = action.payload.id === state.liveProgram?.id
      })
      .addCase(resetCurrentProgram, (state) => {
        state.currentProgram = null
      })

      .addCase(setLiveProgram, (state, action) => {
        state.liveProgram = action.payload
      })
      .addCase(resetLiveProgram, (state) => {
        state.liveProgram = null
      })

      .addCase(setLive, (state, action) => {
        state.isLive = action.payload
      })
  },
})
