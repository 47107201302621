import { useEffect } from 'react'

import { useGetAllChannelsProgramsLiveQuery } from '@/modules/tv/tv.api'
import { setLiveState } from '@/modules/tv/tv.actions'

import { useAppDispatch } from '@/core/store'
import { GET_LIVE_POLLING_INTERVAL } from '@/core/config'

const AllCurrentProgramsFetcher = () => {
  const dispatch = useAppDispatch()

  const { data: liveState } = useGetAllChannelsProgramsLiveQuery(undefined, {
    pollingInterval: isNaN(+GET_LIVE_POLLING_INTERVAL) ? 60000 : +GET_LIVE_POLLING_INTERVAL,
  })

  useEffect(() => {
    if (!liveState) return

    dispatch(setLiveState(liveState))
  }, [liveState])

  return null
}

export default AllCurrentProgramsFetcher
