import React, { Children, cloneElement, FC, useCallback, useEffect, useRef } from 'react'

import classNames from 'classnames'
import styles from './styles.module.scss'

import { FocusContext, FocusHandler, useFocusable } from '@noriginmedia/norigin-spatial-navigation'

import { useHandleWheelScroll } from '@/components/Slider/UseHandleWheelScrollHook'
import { useMutationObserverForListsWithDynamicLength } from '@/core/hooks/useMutationObserverForListsWithDynamicLength'

import { ListProps } from '@/components/List'

interface ListWithScrollbar extends ListProps {}

const ListWithScrollbar: FC<ListWithScrollbar> = ({
  children,
  onInit,
  onListChildFocus,
  className,
  scrollWrapperClassName,
  throttleScrollDelay,
  dynamicLength,
  focusProps,
}) => {
  const scrollingRef = useRef<HTMLDivElement | null>(null)

  const {
    ref,
    focusKey,
    focusSelf,
    hasFocusedChild,
    focused,
    getCurrentFocusKey,
    setFocus,
    navigateByDirection,
  } = useFocusable({
    saveLastFocusedChild: true,
    trackChildren: dynamicLength,
    ...focusProps,
  })

  useMutationObserverForListsWithDynamicLength<HTMLDivElement>({
    hasFocusedChild,
    focused,
    focusSelf,
    observedRef: scrollingRef,
  })

  const scrollBehavior = useRef<ScrollOptions['behavior']>('smooth')

  const reset = useCallback(() => {
    const ref = scrollingRef.current
    if (!ref) return

    parent.scrollTo({ top: 0, behavior: scrollBehavior.current })
  }, [])

  useHandleWheelScroll(scrollingRef, navigateByDirection, throttleScrollDelay)

  const handleChildFocus: FocusHandler = useCallback(
    (...args) => {
      onListChildFocus?.(...args)

      const [layout] = args

      const ref = scrollingRef.current
      if (!ref) return

      const parent = ref.parentElement
      if (!parent) return

      const { y } = layout
      parent.scrollTo({ top: y, behavior: scrollBehavior.current })
    },
    [onListChildFocus],
  )

  const setFocusWithoutScroll = useCallback((focusKey: string) => {
    const ref = scrollingRef.current
    if (!ref) return
    if (!setFocus) return

    scrollBehavior.current = undefined
    setFocus(focusKey)
    scrollBehavior.current = 'smooth'
  }, [])

  useEffect(() => {
    if (!onInit) return

    onInit({ reset, getCurrentFocusKey, setFocus, navigateByDirection, setFocusWithoutScroll })
  }, [])
  return (
    <FocusContext.Provider value={focusKey}>
      <div ref={ref} id={focusKey} className={classNames(styles.List, className)}>
        <div ref={scrollingRef} className={classNames(scrollWrapperClassName)}>
          {Children.map(children, (child) => {
            return cloneElement(child, {
              ...child.props,
              onFocus: handleChildFocus,
              focusProps: {
                ...child.props.focusProps,
                onFocus: handleChildFocus,
              },
            })
          })}
        </div>
      </div>
    </FocusContext.Provider>
  )
}

export default ListWithScrollbar
