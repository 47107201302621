import React from 'react'

import classNames from 'classnames'
import styles from './styles.module.scss'

import {
  getDescriptionFromContentCard,
  getKeywordFromCard,
  getTitleFromContentCard,
} from '@/modules/content/content.helpers'

import { ContentModel } from '@/models/content.model'

import ImdbRating from '@/modules/content/components/ImdbRating'
import KinopoiskRating from '@/modules/content/components/KinopoiskRating'
import AgeInfo from '@/modules/content/components/AgeInfo'
import UhdInfo from '@/modules/content/components/UhdInfo'
import FreeInfo from '@/modules/content/components/FreeInfo'
import ResolveAndRenderPartner from '@/modules/content/components/ResolveAndRenderPartner'

interface ChosenContentDescriptionProps<T> {
  card?: T
  fullDescription?: boolean
  className?: string
  descriptionClassName?: string
}

const ChosenContentDescription = <T extends ContentModel>({
  card,
  className,
  descriptionClassName,
}: ChosenContentDescriptionProps<T>) => {
  return (
    <div
      id={'content-description'}
      data-full={'false'}
      className={classNames(className, styles.DescriptionContainer)}
    >
      <h1 className={classNames(styles.Title)}>{getTitleFromContentCard(card)}</h1>
      <p className={styles.Keywords}>{getKeywordFromCard(card)}</p>
      <div className={styles.Icons}>
        <ResolveAndRenderPartner card={card} className={styles.Icon} />
        <AgeInfo card={card} className={styles.Icon} />
        <UhdInfo card={card} className={styles.Icon} />
        <FreeInfo card={card} className={styles.Icon} />
        <ImdbRating card={card} className={styles.Icon} />
        <KinopoiskRating card={card} className={styles.Icon} />
      </div>
      <div className={classNames(styles.Description, descriptionClassName)}>
        {getDescriptionFromContentCard(card) || 'Описание отсутствует'}
      </div>
    </div>
  )
}

export default ChosenContentDescription
