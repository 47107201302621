import { createApi } from '@reduxjs/toolkit/query/react'
import { createEntityAdapter, EntityState } from '@reduxjs/toolkit'

import { MEDIA_API_URL } from '@/core/config'
import { apiBaseQuery } from '@/core/api/api.query'

import { ContentModel, ContentType } from '@/models/content.model'

enum FavoritesApiTag {
  ALL_FAVORITES_CONTENTS = 'ALL_FAVORITES_CONTENTS',
}

export const favoritesContentAdapter = createEntityAdapter<ContentModel>({
  selectId: (model) => model.id,
})

export const favoritesContentSelectors = favoritesContentAdapter.getSelectors()

export const favoritesMediaApi = createApi({
  reducerPath: 'favoritesMediaApi',
  baseQuery: apiBaseQuery({ baseUrl: `${MEDIA_API_URL}/clients/me/favorites/` }),
  tagTypes: [FavoritesApiTag.ALL_FAVORITES_CONTENTS],
  endpoints: (builder) => ({
    // CONTENT FAVORITES
    getAllFavoritesContent: builder.query<EntityState<ContentModel>, { type?: ContentType }>({
      query: ({ type }) => {
        return {
          url: 'contents',
          method: 'GET',
          params: {
            type,
            relations:
              'posters.verticalImage,posters.horizontalImage,age,partner,countries,backgrounds.image',
          },
        }
      },
      transformResponse: (response: ContentModel[]) => {
        return favoritesContentAdapter.addMany(favoritesContentAdapter.getInitialState(), response)
      },
      serializeQueryArgs: ({ endpointName, queryArgs }) => {
        return endpointName + '--' + queryArgs.type
      },
      providesTags: [FavoritesApiTag.ALL_FAVORITES_CONTENTS],
    }),

    deleteContentFromFavorites: builder.mutation<ContentModel[], { type?: ContentType }>({
      query: ({ type }) => {
        return {
          url: 'contents',
          method: 'DELETE',
          params: {
            type,
          },
        }
      },
      invalidatesTags: [FavoritesApiTag.ALL_FAVORITES_CONTENTS],
    }),

    getFavoritesContentByContentId: builder.query<'', { id: string }>({
      query: ({ id }) => {
        return {
          url: `contents/${id}`,
          method: 'GET',
        }
      },
    }),

    addContentToFavorites: builder.mutation<ContentModel, { id: string }>({
      query: ({ id }) => {
        return {
          url: `contents/${id}`,
          method: 'POST',
        }
      },
      invalidatesTags: [FavoritesApiTag.ALL_FAVORITES_CONTENTS],
    }),

    removeContentFromFavorites: builder.mutation<ContentModel, { id: string }>({
      query: ({ id }) => {
        return {
          url: `contents/${id}`,
          method: 'DELETE',
        }
      },
      invalidatesTags: [FavoritesApiTag.ALL_FAVORITES_CONTENTS],
    }),
  }),
})

export const {
  useGetAllFavoritesContentQuery,
  useGetFavoritesContentByContentIdQuery,
  useAddContentToFavoritesMutation,
  useRemoveContentFromFavoritesMutation,
} = favoritesMediaApi
