import { ContentModel, ContentType } from '@/models/content.model'
import { MovieModel } from '@/models/movie.model'
import { ChannelModel } from '@/models/channel.model'
import { GenreModel } from '@/models/genre.model'
import { FeedsResourceBaseModel, FeedsResourceTypes } from '@/models/feeds.resource.model'
import { RESOURCES_CHUNK_SIZE } from '@/core/config'

export const isMovie = (card: ContentModel): card is MovieModel => {
  return card.type === ContentType.MOVIE
}

export const isChannel = (card: ChannelModel | ContentModel | GenreModel): card is ChannelModel => {
  return !!(card as ChannelModel).archiveStatus
}

export const isContent = (
  card?: ChannelModel | ContentModel | GenreModel | null,
): card is ContentModel => {
  if (!card) return false
  return !!(card as ContentModel).type
}

export const isGenre = (card: ChannelModel | ContentModel | GenreModel): card is GenreModel => {
  return !!(card as GenreModel).pluralTitle
}

export const getResourcesChunks = (
  resources: FeedsResourceBaseModel[],
  chunkSize: number = RESOURCES_CHUNK_SIZE,
) => {
  return Array.from({ length: Math.ceil(resources.length / chunkSize) }, (v, i) =>
    resources.slice(i * chunkSize, i * chunkSize + chunkSize),
  )
}

export const isContentResource = (resource: FeedsResourceBaseModel): boolean => {
  return (
    resource.type === FeedsResourceTypes.GENRE_CONTENT ||
    resource.type === FeedsResourceTypes.TAG_CONTENT ||
    resource.type === FeedsResourceTypes.PARTNER_CONTENT
  )
}

export const isFavoriteContentResource = (resource: FeedsResourceBaseModel): boolean => {
  return resource.type === FeedsResourceTypes.FAVORITE_CONTENT
}

export const isIWatchResource = (resource: FeedsResourceBaseModel): boolean => {
  return resource.type === FeedsResourceTypes.VIEW_CONTENT
}

export const isFavoriteChannelsResource = (resource: FeedsResourceBaseModel): boolean => {
  return resource.type === FeedsResourceTypes.FAVORITE_CHANNEL
}

export const isChannelsResource = (resource: FeedsResourceBaseModel): boolean => {
  return resource.type === FeedsResourceTypes.CATEGORY_CHANNEL
}

export const isGenresListResource = (resource: FeedsResourceBaseModel): boolean => {
  return resource.type === FeedsResourceTypes.GENRE_LIST
}
