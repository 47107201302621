import { useAppSelector } from '@/core/store'
import { useGetNextProgram } from '@/modules/tv/hooks/useGetNextProgram'
import { useEffect, useMemo } from 'react'
import { useLazyGetRightsCheckByChannelIdQuery } from '@/modules/tv/tv.api'
import {
  useLazyGetStreamsByChannelIdAndProgramIdQuery,
  useLazyGetStreamsByChannelIdQuery,
} from '@/modules/streams/streams.channels.api'

export const useGetChannelStreams = () => {
  const channel = useAppSelector((state) => state.tv.chosenChannel)
  const { nextProgram, currentProgram, liveProgram } = useGetNextProgram()

  const isLive = useMemo(() => {
    if (!liveProgram || !currentProgram) return true
    return liveProgram.id === currentProgram.id
  }, [liveProgram, currentProgram])

  const rightsParams = useMemo(() => {
    return {
      channelId: channel?.id || '',
    }
  }, [channel])

  const [
    checkRights,
    { isLoading: rightsLoading, isFetching: rightsFetching, error: rightsError },
  ] = useLazyGetRightsCheckByChannelIdQuery()

  const [
    fetchLiveStreams,
    { data: liveStreams, isLoading: liveStreamsLoading, isFetching: liveStreamsFetching },
  ] = useLazyGetStreamsByChannelIdQuery()
  const [
    fetchProgramStreams,
    { data: programStreams, isLoading: programStreamsLoading, isFetching: programStreamsFetching },
  ] = useLazyGetStreamsByChannelIdAndProgramIdQuery()

  useEffect(() => {
    // Проверка прав при каждой смене канала
    if (!channel) return

    // После положительной проверки запрос за Live стримом с полученным токеном
    checkRights(rightsParams)
      .unwrap()
      .then(({ streamAccessToken: token }) => {
        fetchLiveStreams({ channelId: channel.id, token }, true)
      })
      .catch(console.log)
  }, [channel])

  useEffect(() => {
    // Проверка прав при каждой смене программы
    // если программы нет или нет лайв программы скипаем проверку
    if (!currentProgram || !liveProgram) return
    if (!currentProgram.id || !liveProgram.id) return
    if (currentProgram.id === liveProgram.id || currentProgram.channelId !== liveProgram.channelId)
      return

    checkRights(rightsParams)
      .unwrap()
      .then(({ streamAccessToken: token }) => {
        fetchProgramStreams(
          { channelId: currentProgram.channelId, programId: currentProgram.id, token },
          true,
        )
      })
      .catch(console.log)
  }, [currentProgram, liveProgram])

  const streams = useMemo(() => {
    if (!liveStreams && !programStreams) return ''
    if (isLive && liveStreams) return liveStreams
    if (programStreams) return programStreams

    return ''
  }, [liveStreams, programStreams, isLive])

  const loading = useMemo(() => {
    return (
      rightsLoading ||
      rightsFetching ||
      liveStreamsLoading ||
      liveStreamsFetching ||
      programStreamsLoading ||
      programStreamsFetching
    )
  }, [
    rightsLoading,
    rightsFetching,
    liveStreamsLoading,
    liveStreamsFetching,
    programStreamsLoading,
    programStreamsFetching,
  ])

  return {
    channel,
    loading,
    currentProgram,
    liveProgram,
    nextProgram,
    rightsError,
    streams,
    isLive,
  }
}
