import React, { useEffect, useMemo } from 'react'
import dayjs from 'dayjs'
import styles from './styles.module.scss'

import SkeletonView from '@/components/SkeletonView'

import { useAppSelector } from '@/core/store'

import {
  allChannelsProgramsLiveAdapter,
  useGetTodayProgramsByChannelIdQuery,
} from '@/modules/tv/tv.api'

import { ChannelModel } from '@/models/channel.model'
import { ProgramBaseModel } from '@/models/program.model'

const prepareTime = (program?: ProgramBaseModel) => {
  if (!program) return

  return dayjs(program.startTime).format('HH:mm') + ' - ' + dayjs(program.endTime).format('HH:mm')
}

interface Props {
  channel?: ChannelModel
  getLive?: (live?: ProgramBaseModel) => void
}

const ShortProgramListWithDescription = ({ channel, getLive }: Props) => {
  const { data: programs } = useGetTodayProgramsByChannelIdQuery(
    { channelId: channel?.id || '' },
    {
      skip: !channel,
    },
  )

  const liveProgram = useAppSelector((state) => {
    if (!state.tv.liveState || !channel) return
    return allChannelsProgramsLiveAdapter.getSelectors().selectById(state.tv.liveState, channel.id)
  })

  const nextProgram = useMemo(() => {
    if (!liveProgram || !programs) return

    const liveIndex = programs.findIndex((program) => {
      return program.id === liveProgram.id
    })

    if (liveIndex === -1) return

    return programs[liveIndex + 1]
  }, [liveProgram, programs])

  useEffect(() => {
    if (!liveProgram) return

    if (getLive) {
      getLive(liveProgram)
    }
  }, [liveProgram])

  return (
    <div className={styles.ShortProgramList}>
      <div className={styles.Item}>
        <div className={styles.Title}>{liveProgram?.title || <SkeletonView />}</div>
        <div className={styles.Time}>{prepareTime(liveProgram) || <SkeletonView />}</div>

        <div className={styles.Description}>{liveProgram?.description || <SkeletonView />}</div>
      </div>

      <div className={styles.Item}>
        <div className={styles.Title}>{nextProgram?.title || <SkeletonView />}</div>
        <div className={styles.Time} style={{ marginBottom: '0' }}>
          {prepareTime(nextProgram) || <SkeletonView />}
        </div>
      </div>
    </div>
  )
}

export default ShortProgramListWithDescription
