import React, {
  Children,
  cloneElement,
  FC,
  ReactElement,
  useCallback,
  useEffect,
  useRef,
} from 'react'

import classNames from 'classnames'
import styles from './styles.module.scss'

import {
  FocusContext,
  FocusDetails,
  FocusHandler,
  useFocusable,
  UseFocusableConfig,
} from '@noriginmedia/norigin-spatial-navigation'

import { useHandleWheelScroll } from '@/components/Slider/UseHandleWheelScrollHook'
import { useMutationObserverForListsWithDynamicLength } from '@/core/hooks/useMutationObserverForListsWithDynamicLength'

export interface InitListActions {
  reset?: () => void
  getCurrentFocusKey?: () => string
  setFocus?: (val: string) => void
  navigateByDirection?: (direction: string, focusDetails: FocusDetails) => void
  setFocusWithoutScroll?: (val: string) => void
}

export interface ListProps {
  className?: string
  scrollWrapperClassName?: string
  children: ReactElement[] | ReactElement
  onListChildFocus?: FocusHandler
  onInit?: (args: InitListActions) => void
  throttleScrollDelay?: number
  focusProps?: UseFocusableConfig
  dynamicLength?: boolean
}

const List: FC<ListProps> = ({
  children,
  onInit,
  onListChildFocus,
  className,
  scrollWrapperClassName,
  throttleScrollDelay,
  dynamicLength = false,
  focusProps,
}) => {
  const scrollingRef = useRef<HTMLDivElement | null>(null)

  const {
    ref,
    focusKey,
    focused,
    hasFocusedChild,
    focusSelf,
    getCurrentFocusKey,
    setFocus,
    navigateByDirection,
  } = useFocusable({
    saveLastFocusedChild: true,
    trackChildren: dynamicLength,
    ...focusProps,
  })

  useMutationObserverForListsWithDynamicLength<HTMLDivElement>({
    hasFocusedChild,
    focused,
    focusSelf,
    observedRef: scrollingRef,
  })

  const reset = useCallback(() => {
    const ref = scrollingRef.current
    if (!ref) return

    ref.style.transform = `translateY(-${0}px)`
  }, [])

  const setFocusWithoutScroll = useCallback((focusKey: string) => {
    const ref = scrollingRef.current
    if (!ref) return
    if (!setFocus) return

    ref.classList.remove(styles.ListScrollWrapper)
    setFocus(focusKey)
    ref.classList.add(styles.ListScrollWrapper)
  }, [])

  const handleChildFocus: FocusHandler = useCallback(
    (...args) => {
      onListChildFocus?.(...args)

      const [layout] = args

      const ref = scrollingRef.current
      if (!ref) return
      const parent = ref.parentElement
      if (!parent) return

      const offsetHeight = parent.offsetHeight
      const scrollHeight = ref.scrollHeight

      const { y, height } = layout
      const position = ref.style.transform.slice(12).slice(0, -3) || 0
      if (+position + offsetHeight < y + height) {
        const add =
          // todo: scroll restore
          position === 0 && y > offsetHeight
            ? y - height
            : +position + height + offsetHeight <= scrollHeight
            ? +position + height
            : scrollHeight - offsetHeight

        ref.style.transform = `translateY(-${add}px)`
        return
      }
      if (+position > y) {
        const remove = +position > y ? y : +position - height
        ref.style.transform = `translateY(-${remove}px)`
      }
    },
    [onListChildFocus],
  )

  useHandleWheelScroll(scrollingRef, navigateByDirection, throttleScrollDelay)

  useEffect(() => {
    if (!onInit) return

    onInit({ reset, getCurrentFocusKey, setFocus, navigateByDirection, setFocusWithoutScroll })
  }, [])

  return (
    <FocusContext.Provider value={focusKey}>
      <div
        ref={ref}
        id={focusKey}
        // onWheel={handleOnMouseWheel}
        className={classNames(styles.List, className)}
      >
        <div
          ref={scrollingRef}
          className={classNames(styles.ListScrollWrapper, scrollWrapperClassName)}
        >
          {Children.map(children, (child) => {
            return cloneElement(child, {
              ...child.props,
              onFocus: handleChildFocus,
              focusProps: {
                ...child.props.focusProps,
                onFocus: handleChildFocus,
              },
            })
          })}
        </div>
      </div>
    </FocusContext.Provider>
  )
}

export default List
